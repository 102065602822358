import React, { Fragment, useEffect, useState } from 'react';
// style
import { CustomerDataStyle } from 'styles/ticketForm.style';

// components
import GovernmentId from './governmentId';

import { fetchOccupation } from 'actions/occupation';
import { connect } from 'react-redux';
import { fetchBarangays, fetchCities, fetchProvinces } from 'actions/location';
import { updateCustomerGovernmentId } from 'actions/kyc';
import { makeUpdateTicketUserInfo, fetchTimelineTicket } from 'actions/tickets';
import { useParams } from 'react-router-dom';
import { updateNotification } from 'actions/notification';
// import { isEmpty } from '@ramda/isempty/isEmpty';
// import { isNil } from '@ramda/isnil/isNil';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import SwitchToggle from 'components/shared/switchToggle';
// import { diff_dates } from 'utils/helper'

// utils
import { onClickUrl, jsonParse } from 'utils/helper';

const moment = require('moment');

const SupportCustomerData = ({
  data,
  governmentIDCallback,
  doUpdateCustomerGovernmentId,
  doFetchOccupation,
  doFetchProvinces,
  doFetchCities,
  doFetchBarangays,
  doMakeUpdateTicketUserInfo,
  doUpdateNotification,
  doFetchTimelineTicket,
  callback,
}) => {
  const { ticketNo } = useParams();

  const {
    // eslint-disable-next-line no-unused-vars
    generalData: { user = {}, customer = {}, status_raw },
    customerLocationData = {},
    personalKycData: { risk_type },
  } = data || {
    generalData: {},
    customerLocationData: {},
    personalKycData: {},
  };
  // states

  // const [_disableUpload, _disableUploadHandler] = useState(false);
  // const [occupationOption, occupationOptionHandler] = useState([]);
  const [provinces, provincesHandler] = useState([]);
  const [cities, citiesHandler] = useState([]);
  const [barangays, barangaysHandler] = useState([]);
  const [cityLoading, cityLoadingHandler] = useState(false);
  const [barangayLoading, barangayLoadingHandler] = useState(false);
  const [editToggle, editToggleHandler] = useState(true);
  const [kycVerified, kycVerifiedHandler] = useState(
    customer && customer.kyc_verified ? 'true' : 'false'
  );

  const [formData, formDataHandler] = useState({
    auto_renewal: user ? user.auto_renewal : false,
    first_name: user ? user.first_name : '',
    middle_name: user ? user.middle_name : '',
    last_name: user ? user.last_name : '',
    email: user ? user.email : '',
    mobile: user ? user.mobile : '',
    gender: customer && customer.gender ? customer.gender : '',
    birthdate:
      customer && customer.birthdate
        ? moment(customer.birthdate).format('YYYY-MM-DD')
        : '',
    // occupation:
    //   customer && customer.occupation_id ? customer.occupation_id : '',
    occupation:
      customer && customer.emp_occupation ? customer.emp_occupation : '',
    fund_source: customer && customer.fund_source ? customer.fund_source : '',
    employer: customer && customer.employer ? customer.employer : '',
    business:
      customer && customer.business
        ? customer.business
        : customer.self_business
          ? customer.self_business
          : '',
    address: customerLocationData.address,
    province: customerLocationData.province
      ? customerLocationData.province.id
      : '',
    city: customerLocationData.city ? customerLocationData.city.id : '',
    barangay: customerLocationData.barangay
      ? customerLocationData.barangay.id
      : '',
    other_source_of_funds: customer.other_source_of_funds
      ? customer.other_source_of_funds
      : '',
    self_address: customer.self_address ? customer.self_address : '',
    self_business_name: customer.self_business_name
      ? customer.self_business_name
      : '',
    self_income: customer.self_income ? customer.self_income : '',
    self_phone_number: customer.self_phone_number
      ? customer.self_phone_number
      : '',
    self_years_in_business: customer.self_years_in_business
      ? customer.self_years_in_business
      : '',
    emp_address: customer.emp_address ? customer.emp_address : '',
    emp_business: customer.emp_business ? customer.emp_business : '',
    emp_income: customer.emp_income ? customer.emp_income : '',
    emp_occupation: customer.emp_occupation ? customer.emp_occupation : '',
    emp_phone_number: customer.emp_phone_number
      ? customer.emp_phone_number
      : '',
    emp_position: customer.emp_position ? customer.emp_position : '',
    emp_years: customer.emp_years ? customer.emp_years : '',
  });
  // console.log('customer', data);

  // const getOccupationOptions = () => {
  //     doFetchOccupation()
  //         .then(({ data }) => {
  //             occupationOptionHandler(data);
  //         })
  //         .catch((err) => {
  //             console.log('err', err);
  //         });
  // };


  // const disabled_upload = async () => {
  //   const today = new Date (Date.now())
  //   const {
  //     data: { trails },
  //   } = await doFetchTimelineTicket(ticketNo);
  //   if (status_raw === "LR"){
  //     const lr_date = trails.find(el => el.activity_raw === "LR").inserted_at
  //     _disableUploadHandler(diff_dates(new Date(lr_date), today) >= 1)
  //     return
  //   }
  //   _disableUploadHandler(false)
  //   return
  // }
  

  const getProvincesOptions = () => {
    doFetchProvinces({ page_size: -1 }).then((rdata) => {
      const {
        data: { provinces },
      } = rdata;
      provincesHandler(provinces);
    });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      // getOccupationOptions();
      getProvincesOptions();
      // disabled_upload()

      const { province, city } = customerLocationData;
      if (province) {
        cityLoadingHandler(true);
        doFetchCities(province.id).then(({ data: { cities } }) => {
          citiesHandler(cities || []);
          cityLoadingHandler(false);
        });
      }

      if (city) {
        barangayLoadingHandler(true);

        doFetchBarangays(city.id).then(({ data: { barangays } }) => {
          barangaysHandler(barangays || []);
          barangayLoadingHandler(false);
        });
      }
    }
    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);

  const getCitiesOption = (province) => {
    if (province) {
      cityLoadingHandler(true);
      doFetchCities(province).then(({ data: { cities } }) => {
        citiesHandler(cities || []);
        cityLoadingHandler(false);
      });
    }
  };

  const getBarangayOption = (city) => {
    if (city) {
      barangayLoadingHandler(true);
      doFetchBarangays(city).then(({ data: { barangays } }) => {
        barangaysHandler(barangays || []);
        barangayLoadingHandler(false);
      });
    }
  };

  const fireUpdate = () => {
    const {
      auto_renewal,
      first_name,
      middle_name,
      last_name,
      mobile,
      address,
      barangay,
      city,
      province,
      gender,
      fund_source,
      employer,
      business,
      occupation,
      birthdate,
    } = formData;

    const _payload = {
      user: {
        mobile,
        first_name,
        middle_name,
        last_name,
        auto_renewal,
      },
      customer: {
        address,
        barangay,
        city,
        province,
        gender,
        birthdate:
          birthdate !== ''
            ? moment(birthdate).format('YYYY-MM-DD 00:00:00')
            : '',
        occupation_id: occupation,
        fund_source,
        employer,
        business,
      },
      ticket_no: ticketNo,
    };

    doMakeUpdateTicketUserInfo(_payload)
      .then((response) => {
        const { success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `successfully update ticket customer info`,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
        callback();
      })
      .catch((err) => {
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  const filePreviewSrc = (src) => {
    if (src.split('.').pop() === 'pdf') {
      return 'https://s3.us-west-2.amazonaws.com/pawnhero.ph/assets/PDF_file_icon.png';
    }
    return src;
  };

  const triggerUpdate = async (e) => {
    let isChecked = e.target.value;

    kycVerifiedHandler(isChecked);

    const payload = {
      customer_data: {
        kyc_verified: isChecked,
      },
      ticket_no: ticketNo,
    };

    try {
      const { success } = await doUpdateCustomerGovernmentId(payload);

      if (success) {
        doUpdateNotification({
          toggle: true,
          message: `successfully update ticket customer info`,
          color: 'primary',
        });
      } else {
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      }
    } catch (e) {
      doUpdateNotification({
        toggle: true,
        message:
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
        color: 'danger',
      });
    }
  };

  return (
    <>
      <div className='column is-6'>
        <CustomerDataStyle className='columns'>
          <div className='column is-6 is-full-mobile'>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <SwitchToggle
                  id='toggleSwitch'
                  is_active={editToggle}
                  onClick={() => {
                    editToggleHandler(!editToggle);
                  }}
                  label={() => (
                    <>
                      <p
                        style={{
                          height: '100%',
                          textAlign: 'center',
                          padding: '7px',
                        }}>
                        Lock
                      </p>
                      <p
                        style={{
                          heigh: '100%',
                          textAlign: 'center',
                          padding: '7px',
                        }}>
                        Edit
                      </p>
                    </>
                  )}
                  labelStyle={{
                    fontSize: '12px',
                  }}
                  labelActiveColor={{
                    enable: '#e2574c',
                    disable: '#68c552',
                  }}
                />
              </div>
              {/* <div className='content-row'>
                <p className='sub-title'>
                  Auto Renewal{' '}
                  <span className='has-text-weight-semibold'>(User level)</span>
                </p>
                <div className='select'>
                  <select
                    value={formData.auto_renewal}
                    style={{ fontSize: '14px' }}
                    disabled={editToggle}
                    onChange={(e) =>
                      formDataHandler({
                        ...formData,
                        auto_renewal: e.target.value,
                      })
                    }>
                    <option value=''>Select Option</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>
              </div> */}
              <div className='content-row'>
                <p className='sub-title'>First Name</p>
                <input
                  type='text'
                  className='input'
                  value={formData.first_name || ''}
                  onChange={(e) =>
                    formDataHandler({ ...formData, first_name: e.target.value })
                  }
                  disabled={editToggle}
                />
              </div>
              <div className='content-row'>
                <p className='sub-title'>Middle Name</p>
                <input
                  type='text'
                  className='input'
                  value={formData.middle_name || ''}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      middle_name: e.target.value,
                    })
                  }
                  disabled={editToggle}
                />
              </div>
              <div className='content-row'>
                <p className='sub-title'>Last Name</p>
                <input
                  type='text'
                  className='input'
                  value={formData.last_name || ''}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      last_name: e.target.value,
                    })
                  }
                  disabled={editToggle}
                />
              </div>
              <div className='content-row'>
                <p className='sub-title'>Pawner Email Address</p>
                <input
                  type='email'
                  className='input'
                  value={formData.email}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      email: e.target.value,
                    })
                  }
                  disabled
                />
              </div>
              <div className='content-row'>
                <p className='sub-title'>Pawner Mobile Number</p>
                <input
                  type='text'
                  className='input'
                  value={formData.mobile}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      mobile: e.target.value,
                    })
                  }
                  disabled={editToggle}
                />
              </div>

              <div className='content-row'>
                <p className='sub-title'>Sex</p>
                <div className='select'>
                  <select
                    value={formData.gender}
                    onChange={(e) =>
                      formDataHandler({
                        ...formData,
                        gender: e.target.value,
                      })
                    }
                    style={{ fontSize: '14px' }}
                    disabled={editToggle}>
                    <option value=''>Select Gender</option>
                    <option value='Male'>Male</option>
                    <option value='Female'>Female</option>
                  </select>
                </div>
              </div>
              <div className='content-row'>
                <p className='sub-title'>Date of birth</p>
                <SimpleDatePicker
                  className='input'
                  value={formData.birthdate}
                  onChange={(e) => {
                    formDataHandler({
                      ...formData,
                      birthdate: e,
                    });
                  }}
                  disabled={editToggle}
                />
              </div>
              <div className='content-row'>
                <p className='sub-title'>Source of funds</p>
                <input
                  type='text'
                  className='input'
                  value={formData.fund_source || ''}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      fund_source: e.target.value,
                    })
                  }
                  disabled={editToggle}
                />
              </div>
              {formData.fund_source !== 'Others' ? (
                <>
                  {formData.fund_source === 'Employment' && (
                    <>
                      <div className='content-row'>
                        <p className='sub-title'>Name of employer</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.employer || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              employer: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>

                      <div className='content-row'>
                        <p className='sub-title'>Years With Employer</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.emp_years || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              emp_years: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>

                      <div className='content-row'>
                        <p className='sub-title'>Occupation</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.emp_occupation || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              emp_occupation: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>

                      <div className='content-row'>
                        <p className='sub-title'>Position / Rank</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.emp_position || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              emp_position: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>

                      <div className='content-row'>
                        <p className='sub-title'>Gross Monthly Income</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.emp_income || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              emp_income: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Employer’s Address</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.emp_address || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              emp_address: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Employer’s Phone Number</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.emp_phone_number || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              emp_phone_number: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                    </>
                  )}
                  <div className='content-row'>
                    <p className='sub-title'>Nature of business</p>
                    <input
                      type='text'
                      className='input'
                      value={formData.business || ''}
                      onChange={(e) =>
                        formDataHandler({
                          ...formData,
                          business: e.target.value,
                        })
                      }
                      disabled={editToggle}
                    />
                  </div>

                  {formData.fund_source === 'Business' && (
                    <>
                      <div className='content-row'>
                        <p className='sub-title'>Name of Business</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.self_business_name || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              self_business_name: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>

                      <div className='content-row'>
                        <p className='sub-title'>Year in Business</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.self_years_in_business || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              self_years_in_business: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Gross Monthly Income</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.self_income || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              self_income: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Business Address</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.self_address || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              self_address: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Business Contact No.</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.self_phone_number || ''}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              self_phone_number: e.target.value,
                            })
                          }
                          disabled={editToggle}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Other Source of Funds</p>
                    <input
                      type='text'
                      className='input'
                      value={formData.other_source_of_funds || ''}
                      onChange={(e) =>
                        formDataHandler({
                          ...formData,
                          occupation: e.target.value,
                        })
                      }
                      disabled={editToggle}
                    />
                  </div>
                </>
              )}
              {formData.other_employment_status && (
                <div className='content-row'>
                  <p className='sub-title'>Other Employment Status</p>
                  <input
                    type='text'
                    className='input'
                    value={formData.other_employment_status || ''}
                    onChange={(e) =>
                      formDataHandler({
                        ...formData,
                        occupation: e.target.value,
                      })
                    }
                    disabled={editToggle}
                  />
                </div>
              )}

              <div className='content-row'>
                <p className='sub-title'>Risk Assessment</p>
                <div className='select'>
                  <select
                    value={risk_type}
                    disabled
                    style={{ fontSize: '14px' }}>
                    <option value=''>Select Risk</option>
                    <option value='low'>Low Risk</option>
                    <option value='normal'>Normal Risk</option>
                    <option value='high'>High Risk</option>
                  </select>
                </div>
              </div>
              <div className='content-row'>
                <p className='value'>Permanent Address</p>
              </div>
              <div className='content-row'>
                <p className='sub-title'>Street Address</p>
                <input
                  type='text'
                  className='input'
                  value={formData.address || ''}
                  onChange={(e) =>
                    formDataHandler({
                      ...formData,
                      address: e.target.value,
                    })
                  }
                  disabled={editToggle}
                />
              </div>

              <div className='content-row'>
                <p className='sub-title'>Province</p>
                <div className='select'>
                  <select
                    value={formData.province}
                    onChange={(e) => {
                      formDataHandler({
                        ...formData,
                        province: e.target.value,
                        city: '',
                        barangay: '',
                      });
                      getCitiesOption(e.target.value);
                    }}
                    style={{ fontSize: '14px' }}
                    disabled={editToggle}>
                    <option value=''>Select Province</option>
                    {provinces.map((province, index) => {
                      return (
                        <Fragment key={index}>
                          <option value={province.id}>{province.name}</option>
                        </Fragment>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className='content-row'>
                <p className='sub-title'>City</p>
                <div className='select'>
                  {cityLoading ? (
                    <p>loading..</p>
                  ) : (
                    <select
                      value={formData.city}
                      onChange={(e) => {
                        formDataHandler({
                          ...formData,
                          city: e.target.value,
                          barangay: '',
                        });
                        getBarangayOption(e.target.value);
                      }}
                      style={{ fontSize: '14px' }}
                      disabled={!formData.province || editToggle}>
                      <option value=''>Select City</option>
                      {cities.map((city, index) => {
                        return (
                          <Fragment key={index}>
                            <option value={city.id}>{city.name}</option>
                          </Fragment>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>

              <div className='content-row'>
                <p className='sub-title'>Barangay</p>
                <div className='select'>
                  {barangayLoading ? (
                    <p>loading..</p>
                  ) : (
                    <select
                      value={formData.barangay}
                      onChange={(e) =>
                        formDataHandler({
                          ...formData,
                          barangay: e.target.value,
                        })
                      }
                      style={{ fontSize: '14px' }}
                      disabled={!formData.city || editToggle}>
                      <option value=''>Select Barangay</option>
                      {barangays.map((barangay, index) => {
                        return (
                          <Fragment key={index}>
                            <option value={barangay.id}>{barangay.name}</option>
                          </Fragment>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>

              <div className='content-row'>
                <button
                  className='button is-primary full-width'
                  onClick={() => {
                    fireUpdate();
                  }}
                  disabled={
                    // Object.keys(formData).filter(
                    //   (key) =>
                    //     key !== 'middle_name' &&
                    //     (isEmpty(formData[key]) ||
                    //       isNil(formData[key] || !formData[key]))
                    // ).length ||
                    editToggle
                  }>
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className='column is-6 is-full-mobile'>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='value'>Government ID</p>
                <GovernmentId governmentIDCallback={governmentIDCallback} />
              </div>
              <div className='content-row'>
                <p className='value'>KYC</p>
                <div style={{ marginTop: '10px' }}>
                  <SimpleReactLightbox>
                    <SRLWrapper>
                      {customer && customer.kyc_image && (
                        <>
                          {jsonParse(customer.kyc_image).map((file, index) => (
                            <Fragment key={index}>
                              {file.src.split('.').pop() !== 'pdf' && (
                                <>
                                  <div className='kyc-image-wrapper ekyc-image-wrapper'>
                                    <img
                                      className='uploaded-image'
                                      src={filePreviewSrc(file.src)}
                                      alt=''
                                      style={{
                                        border: '1px dashed #e1e6e9',
                                        borderRadius: '6px',
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                            </Fragment>
                          ))}
                        </>
                      )}
                    </SRLWrapper>
                  </SimpleReactLightbox>

                  {customer && customer.kyc_image && (
                    <>
                      {jsonParse(customer.kyc_image).map((file, index) => (
                        <Fragment key={index}>
                          {file.src.split('.').pop() === 'pdf' && (
                            <>
                              <div className='kyc-image-wrapper ekyc-image-wrapper'>
                                <img
                                  className='uploaded-image'
                                  src={filePreviewSrc(file.src)}
                                  onClick={onClickUrl(file.src)}
                                  alt=''
                                />
                              </div>
                            </>
                          )}
                        </Fragment>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className='content-row'>
                <p className='value'>KYC Verification</p>
                <p className='is-size-7'>
                  {' '}
                  Has been check in UNSC sanction list / and other local
                  watchlist
                </p>
                <div className='control'>
                  <label className='radio'>
                    <input
                      type='radio'
                      value={true}
                      name='kyc_verification'
                      checked={kycVerified === 'true'}
                      onChange={(e) => triggerUpdate(e)}
                    />{' '}
                    Listed
                  </label>
                </div>
                <div className='control'>
                  <label className='radio'>
                    <input
                      type='radio'
                      value={false}
                      name='kyc_verification'
                      checked={kycVerified === 'false'}
                      onChange={(e) => triggerUpdate(e)}
                    />{' '}
                    Not listed
                  </label>
                </div>
              </div>
            </div>
          </div>
        </CustomerDataStyle>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchOccupation: () => dispatch(fetchOccupation()),
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doFetchCities: (provinceID) => dispatch(fetchCities(provinceID)),
  doFetchBarangays: (cityID) => dispatch(fetchBarangays(cityID)),
  doMakeUpdateTicketUserInfo: (payload) =>
    dispatch(makeUpdateTicketUserInfo(payload)),
  doUpdateCustomerGovernmentId: (payload) =>
    dispatch(updateCustomerGovernmentId(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchTimelineTicket: (ticketNo) => dispatch(fetchTimelineTicket(ticketNo)),
});

export default connect(null, mapDispatchToProps)(SupportCustomerData);
