import React, { useState, useEffect, Fragment } from 'react';

//utils
import { connect } from 'react-redux';
import pick from '@ramda/pick';
// import { useHistory } from 'react-router-dom';

// components
import Layout from 'components/layout';
import { Container } from 'react-bulma-components';
import Pagination from 'components/shared/pagination';
import DataTable from 'components/dataTable';
import TableHeader from 'components/contentHeader/tableHeader';
import { DownLoadLinkCell } from 'components/dataTable/cell';
import { updateConfigs } from 'actions/config';
import { fetchReports, makeGenerateReport } from 'actions/analytics';
import { updateNotification } from 'actions/notification';
import { TicketDataTableStyle } from 'styles/ticket.style';
import DatePicker from "react-datepicker";
import { Columns, Form, Button, Box } from 'react-bulma-components';
import { format } from 'date-fns';
const { Column } = Columns;
const { Label } = Form;
// import { removeEmpty } from 'utils/helper';

const moment = require('moment');

const AnalyticsReport = ({
  doFetchReports,
  doUpdateConfigs,
  doUpdateNotification,
  doMakeGenerateReport,
}) => {
  // const history = useHistory();
  const column = [
    {
      headerName: 'ID',
      field: 'id',
      sortable: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
    },
    {
      headerName: 'URL',
      field: 'url',
      sortable: true,
      cellRenderer: 'downloadCell',
    },
    {
      headerName: 'Auto Created',
      field: 'is_auto_create',
      sortable: true,
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      sortable: true,
    }
    // {
    //   headerName: '',
    //   field: 'action',
    //   cellRenderer: 'actionCell',
    // },
  ];

  const reports = [
    {name: "Get All Tickets Released", report_name:"GetAllTicketsReleased"},
    {name: "Get Monthly CSStatus", report_name:"GetMonthlyCSStatus"}
  ]

  const [rowData, rowDataHandler] = useState([]);
  const [dataTableError, dataTableErrorHandler] = useState(false);
  const [sort, sortHandler] = useState(null); //0 assc , 1 desc , null off
  const [loading, loadingHandler] = useState(true);
  const [searchKey, searchKeyHandler] = useState("");
  const [pagination, paginationHandler] = useState(null);
  const [sortParam, sortParamHandler] = useState(null);
  const [selectedReport, selectedReportHandler] = useState('');
  const [startDate, startDateHandler] = useState('');
  const [endDate, endDateHandler] = useState('');
  const [isProcessing, isProcessingHandler] = useState(false);
  const [errors, errorHandler] = useState([]);
  const [countInLast30Days, countInLast30DaysHandler] = useState(0);
  

  const onSorthandler = (props) => {
    if (!loading && !dataTableError) {
      const { field, sort } = props;
      let _sort = null;
      let _paramSort = null;
      switch (sort) {
        case null:
          _sort = 0;
          _paramSort = { c: field, o: 'asc' };
          break;
        case 0:
          _sort = 1;
          _paramSort = { c: field, o: 'desc' };
          break;
        case 1:
          _sort = null;
          _paramSort = { c: "inserted_at", o: 'desc' };
          break;
        default:
          _sort = null;
          _paramSort = { c: "inserted_at", o: 'desc' };
      }

      sortHandler({ field, sort: _sort });
      sortParamHandler(_paramSort);

      const page = pagination ? pagination.page_number : 1;
      const page_size = pagination ? pagination.page_size : 10;

      const _urlParams = {
        ..._paramSort,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : "",
      };
      // const cleamParams = removeEmpty(_urlParams);
      getData(_urlParams);
    }
  };
  const generateReport = async () => {
    isProcessingHandler(true);

    const newErrors = [];
    if (!selectedReport) {
        newErrors.push("selectedReport");
    }
    if (!startDate) {
        newErrors.push("startDate");
    }
    if (!endDate) {
        newErrors.push("endDate");
    }

    if (newErrors.length > 0) {
        errorHandler(newErrors);
        isProcessingHandler(false);
    } else {
        errorHandler([]); 
        const payload = {
          "report_name": selectedReport,
          "start_date": format(startDate, "yyyy-MM-dd HH:mm:ss"),
          "end_date": format(endDate, "yyyy-MM-dd HH:mm:ss")
        }
        doMakeGenerateReport(payload).then((response) => {
          const { success } = response;
          if (success) {
            doUpdateNotification({
              toggle: true,
              message:"Generate report",
              color: 'primary',
            });
            getData();
          } else {
            doUpdateNotification({
              toggle: true,
              message:
                'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
              color: 'danger',
            });
          }
          isProcessingHandler(false);
        })
        .catch((err) => {
          console.log('err', err);
          isProcessingHandler(false);
        });
      }
};


  const getData = (params) => {
    dataTableErrorHandler(false);
    loadingHandler(true);
    doFetchReports(params)
      .then((response) => {
        const {
          data,
          page_number,
          page_size,
          success,
          total_entries,
          total_pages,
          count_in_last_30_days
        } = response;
        
        if (success) {
          loadingHandler(false);
          countInLast30DaysHandler(count_in_last_30_days);
          const _data = data.map((e) => ({
            ...e,
            is_auto_create: e.is_auto_create ? "Yes" : "No",
            created_at: e.created_at ? moment(e.created_at).format('YYYY-MM-DD hh:mm A'): '',
            action: {
              ...e,
              action: (payload) => {
                return new Promise((resolve, reject) => {
                  console.log('fire action', payload);
                  doUpdateConfigs(payload)
                    .then((response) => {
                      console.log('response', response);
                      if (success) {
                        doUpdateNotification({
                          toggle: true,
                          message: `successful update config`,
                          color: 'primary',
                        });
                        getData();
                        resolve({ response: true });
                      } else {
                        doUpdateNotification({
                          toggle: true,
                          message:
                            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                          color: 'danger',
                        });
                        reject({ response: false });
                      }
                    })
                    .catch((err) => {
                      console.log('err', err);
                      doUpdateNotification({
                        toggle: true,
                        message:
                          'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
                        color: 'danger',
                      });
                      reject({ response: false });
                    });
                });
              },
            },
          }));
          rowDataHandler(_data);
          paginationHandler({
            total_entries,
            page_size,
            page_number,
            total_pages,
          });
        } else {
          loadingHandler(false);
          dataTableErrorHandler(true);
        }
      })
      .catch((err) => {
        console.log('err', err);
        loadingHandler(false);
        dataTableErrorHandler(true);
      });
  };

  const onPageChange = ({ page, page_size = 10 }) => {
    if (!loading && !dataTableError) {
      paginationHandler({ ...pagination, page_number: page });

      const _urlParams = {
        ...sortParam,
        page,
        page_size,
        search: searchKey ? searchKey.trim() : "",
      };

      // const cleamParams = removeEmpty(_urlParams);
      getData(_urlParams);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <TableHeader
          icon='/assets/analytics-data.svg'
          title='Analytics Report'
        />
        <TicketDataTableStyle>
        <div className='filter-columns'>
          <div
            className='columns is-multiline'
            style={{
              alignItems: 'baseline',
            }}>
            <div className='column is-9 is-12-mobile'>
              <Box>
                <div className='columns'>
                  <Column>
                    <Label size='small' style={{ textAlign: 'left' }}>
                      Report Name
                    </Label>
                      <select
                        className={`input text-dark-blue ${errors.includes("selectedReport") ? 'is-danger' : ''}`}
                        value={selectedReport}
                        onChange={(e) =>
                          selectedReportHandler(e.target.value)
                         }>
                        <option value=''> - SELECT - </option>
                        {reports.map((e, i) => (
                          <Fragment key={i}>
                            <option value={e.report_name}>{e.name}</option>
                          </Fragment>
                        ))}
                      </select>
                    </Column>
                    <Column>
                    <Label size='small' style={{ textAlign: 'left' }}>
                      Start Date
                    </Label>
                      <DatePicker
                        id="start_date"
                        className={`input text-dark-blue ${errors.includes("startDate") ? 'is-danger' : ''}`}
                        selected={startDate || ""}
                        utcOffset={0}
                        maxDate={new Date()}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="yyyy/MM/dd"
                        autoComplete="off"
                        popperPlacement="bottom-start"
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport'
                          }
                        }}
                        onChange={(date) => {
                          startDateHandler(new Date(date));
                        }}
                      />
                    </Column>
                    <Column>
                    <Label size='small' style={{ textAlign: 'left' }}>
                      End Date
                    </Label>
                      <DatePicker
                        id="end_date"
                        className={`input text-dark-blue ${errors.includes("endDate") ? 'is-danger' : ''}`}
                        selected={endDate || ""}
                        utcOffset={0}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="yyyy/MM/dd"
                        minDate={startDate}
                        maxDate={new Date()}
                        autoComplete="off"
                        popperPlacement="bottom-start"
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport'
                          }
                        }}
                        onChange={(date) => {
                          endDateHandler(new Date(date));
                        }}
                      />
                    </Column>
                    <Column 
                      className={"is-4 pb-0 mb-0"}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                    <div className="tooltip-container tooltip-right mr-0">
                      <span className="tooltip-text">Report generation disabled: max 3 manual generates reached.</span>
                      <Button 
                      onClick={() => generateReport()}
                          disabled={isProcessing || countInLast30Days >= 3} 
                          color="success">
                          Generate Report
                      </Button>
                      </div>
                      <Label size='small'>Manual Reports Generated in the Last 30 Days: {countInLast30Days}</Label>
                    </Column>
                </div>
              </Box>
            </div>

           </div>
        </div>
      </TicketDataTableStyle>
        <Container>
          <DataTable
            column={column}
            row={rowData}
            frameworkComponents={{
              downloadCell: DownLoadLinkCell,
              // actionCell: UpdateConfigsButtonCell,
            }}
            onSorthandler={onSorthandler}
            sort={sort}
            loading={loading}
            error={dataTableError}
            pagination={pagination}
            searchKey={searchKey}
            searchKeyHandler={searchKeyHandler}
            loadAction={getData}
            // navigation={{
            //   customButton: {
            //     label: 'Create',
            //     action: () => {
            //       history.push('/setting/config/form/new');
            //     },
            //   },
            // }}
          />

          {pagination && (
            <>
              <Pagination
                total={pagination.total_pages}
                current={pagination.page_number}
                itemNumber={pagination.page_size}
                onChange={onPageChange}
              />
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doFetchReports: (payload) => dispatch(fetchReports(payload)),
  doMakeGenerateReport: (payload) => dispatch(makeGenerateReport(payload)),
  doUpdateConfigs: (payload) => dispatch(updateConfigs(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsReport);
