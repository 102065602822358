import React, { Fragment, useState } from 'react';

import { isEmpty } from '@ramda/isempty/isEmpty';
import { isNil } from '@ramda/isnil/isNil';

// style
import { DropzoneStyle } from 'styles/dropzone.style';
import { useDropzone } from 'react-dropzone';
import uploadService from 'utils/uploadService';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { Modal, Heading } from 'react-bulma-components';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const MyDropZone = (props) => {
  const {
    files = [],
    onFileChange, //component call back
    fileRemovable = false,
    onRemove = false,
    config,
    action
  } = props;

  const [toggle, toggleHandler] = useState(false);
  const [stageRemoveFile, stageRemoveFileHandler] = useState(false);
  const [progress, progressHandler] = useState(0);
  const [uploading, uploadingHandler] = useState(false);
  const [lighboxToggle, lighboxToggleHandler] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const _file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      upload(_file);
    },
  });

  const removeIconClick = (file, index) => {
    stageRemoveFileHandler(false);
    //fire remove image here

    if (onRemove) {
      stageRemoveFileHandler({ file, index });
      toggleHandler(true);
    }
  };

  const upload = (files) => {
    uploadingHandler(true);

    const fileToUpload = files.filter((file) => file instanceof File);
    if (action === 'ticket') {
      ticketUpload(fileToUpload, config.path, config.ptn)
    } else {
      doUpload(fileToUpload)
    }

  };

  const ticketUpload = (fileToUpload, path, ptn) => {
    if (!isEmpty(fileToUpload)) {
      uploadService
        .uploadTicket(config.multiple ? fileToUpload : fileToUpload[0], (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const _percentCompleted =
              percentCompleted > 95 ? 95 : percentCompleted;
            progressHandler(_percentCompleted);
            uploadingHandler(false);
          },
          path,
          ptn
        )
        .then(({ data: dataResponse }) => {
          const { url } = dataResponse;

          clean(fileToUpload); //remove from cache
          //upload api is only for single file
          //TODO update it with multi file
          onFileChange(config.multiple ? url : [{ src: url }]);
          setTimeout(() => {
            progressHandler(100);
            uploadingHandler(false);
          }, 500);
        })
        .catch((err) => {
          console.log('uploadService err', err);
          progressHandler(100);
          uploadingHandler(false);
        });
    }
  }

  const doUpload = (fileToUpload) => {
    if (!isEmpty(fileToUpload)) {
      //TODO update it with multi file
      uploadService
        .upload(
          config.multiple ? fileToUpload : fileToUpload[0],
          (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const _percentCompleted =
              percentCompleted > 95 ? 95 : percentCompleted;
            progressHandler(_percentCompleted);
            uploadingHandler(false);
          }
        )
        .then(({ data: dataResponse }) => {
          const { url } = dataResponse;

          clean(fileToUpload); //remove from cache
          //upload api is only for single file
          //TODO update it with multi file
          onFileChange(config.multiple ? url : [{ src: url }]);
          setTimeout(() => {
            progressHandler(100);
            uploadingHandler(false);
          }, 500);
        })
        .catch((err) => {
          console.log('uploadService err', err);
          progressHandler(100);
          uploadingHandler(false);
        });
    }
  }

  const clean = (files) => {
    if (files) {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  };

  return (
    <>
      <DropzoneStyle progress={progress}>
        <div className='file-container-wrapper'>
          <SimpleReactLightbox>
            <SRLWrapper
              callbacks={{
                onLightboxOpened: (object) => {
                  lighboxToggleHandler(true);
                },
                onLightboxClosed: (object) => {
                  lighboxToggleHandler(false);
                },
              }}>
              {files && !isEmpty(files) && !isNil(files) && (
                <>
                  {files.map((file, index) => (
                    <Fragment key={index}>
                      <div className='image-wrapper'>
                        {fileRemovable && !config.disabled && (
                          <div
                            className='btn-remove'
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              zIndex: '99',
                              display: 'block',
                              color: '#f14668',
                            }}
                            onClick={(e) => removeIconClick(file, index)}>
                            <FontAwesomeIcon
                              icon={faTrash}
                              size='lg'
                              color='light'
                            />
                          </div>
                        )}
                        <img src={file.src} alt='' />
                      </div>
                    </Fragment>
                  ))}
                </>
              )}
              {!lighboxToggle && (
                <>
                  {!uploading &&  (
                    <>
                      { config?.disable_upload?.toLowerCase() === "true" 
                      ? "" 
                      :
                        <div {...getRootProps({ className: 'uploader' })}>
                          <input {...getInputProps({ ...config })} />
                            <img
                              src='/assets/upload-text.svg'
                              alt='upload here'
                              style={{ pointerEvents: 'none' }}
                            />
                        </div>
                      }
                    </>
                  )}
                </>
              )}
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>

        {uploading && (
          <div className='process-wrapper'>
            <div></div>
          </div>
        )}
      </DropzoneStyle>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>
              <Heading subtitle size={6}>
                Are you sure you want to delete this image?
              </Heading>
            </div>
          </Head>
          <Body>
            {stageRemoveFile && (
              <img
                src={(stageRemoveFile.file && stageRemoveFile.file.src) || ''}
                alt=''
                style={{
                  width: '100%',
                  maxWidth: '320px',
                  display: 'block',
                  margin: 'auto',
                }}
              />
            )}
          </Body>
          <Foot>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}>
              <button
                className='button is-primary'
                onClick={() => toggleHandler(false)}>
                Cancel
              </button>
              <button
                className='button is-danger'
                onClick={() => {
                  if (onRemove) {
                    onRemove(stageRemoveFile);
                    toggleHandler(false);
                  }
                }}>
                Remove
              </button>
            </div>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

export default MyDropZone;
