import React, { Fragment, useState } from "react";

import { isEmpty } from "@ramda/isempty/isEmpty";
import { isNil } from "@ramda/isnil/isNil";

// style
import { DropzoneStyle } from "styles/dropzone.style";
import { useDropzone } from "react-dropzone";
import uploadService from "utils/uploadService";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faUpload, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Modal, Heading } from "react-bulma-components";
import Compressor from "compressorjs";

// utils
import { onClickUrl } from "utils/helper";

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;

const MyDropZone = (props) => {
  const {
    files = [],
    onFileChange, //component call back
    fileRemovable = false,
    onRemove = false,
    config,
    action,
    innerText = "Upload File",
  } = props;
  const [toggle, toggleHandler] = useState(false);
  const [stageRemoveFile, stageRemoveFileHandler] = useState(false);
  const [progress, progressHandler] = useState(0);
  const [uploading, uploadingHandler] = useState(false);
  const [lighboxToggle, lighboxToggleHandler] = useState(false);
  const [uploadError, uploadErrorHandler] = useState("");
  // eslint-disable-next-line no-unused-vars
  const disabled_upload = config?.disable_upload?.toLowerCase() === "true"
  const maxSize = 5242880;

  const fileValidator = (file) => {
    console.log(file)
    if (file.size > maxSize) {
      let message = `File is larger than 5mb`
      uploadErrorHandler(message)
      return {
        code: "file-too-large",
        message,
      };
    }
      return null;
    }
    
  const { getRootProps, getInputProps  } = useDropzone({
    accept: "image/*,.pdf",
    minSize: 0,
    maxSize,
    validator: fileValidator,
    onDrop: async (acceptedFiles, rejected) => {
      handleCompressedUpload(acceptedFiles, rejected);
    },
  });

  const handleCompressedUpload = async (files, rejected) => {
    if(files.length > 0){
      const image = files[0];
      if (image.type.match(/image/g)) {
        new Compressor(image, {
          quality: 0.9, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            // setCompressedFile([compressedResult])
            const _file = [compressedResult].map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            );
            upload(_file);
          },
        });
      } else {
        upload(files);
      }
    }
  };

  const removeIconClick = (file, index) => {
    stageRemoveFileHandler(false);
    //fire remove image here

    if (onRemove) {
      stageRemoveFileHandler({ file, index });
      toggleHandler(true);
    }
  };

  const upload = (files) => {
    uploadErrorHandler("")
    uploadingHandler(true);

    const notImage = files.filter((file) => file instanceof File);

    let fileToUpload = notImage.length ? notImage : files;
    if (action === "ticket") {
      ticketUpload(fileToUpload, config.path, config.ptn);
    } else {
      doUpload(fileToUpload);
    }
  };

  const ticketUpload = (fileToUpload, path, ptn) => {
    if (!isEmpty(fileToUpload)) {
      uploadService
        .uploadTicket(
          config.multiple ? fileToUpload : fileToUpload[0],
          (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const _percentCompleted =
              percentCompleted > 95 ? 95 : percentCompleted;
            progressHandler(_percentCompleted);
            uploadingHandler(false);
          },
          path,
          ptn
        )
        .then(({ data: dataResponse }) => {
          const { url } = dataResponse;

          clean(fileToUpload); //remove from cache
          //upload api is only for single file
          //TODO update it with multi file
          onFileChange(config.multiple ? url : [{ src: url }]);
          setTimeout(() => {
            progressHandler(100);
            uploadingHandler(false);
          }, 500);
        })
        .catch((err) => {
          console.log("uploadService err", err);
          uploadErrorHandler("Request Timeout")
          progressHandler(100);
          uploadingHandler(false);
        });
    }
  };

  const doUpload = (fileToUpload) => {
    if (!isEmpty(fileToUpload)) {
      //TODO update it with multi file
      uploadService
        .upload(
          config.multiple ? fileToUpload : fileToUpload[0],
          (progressEvent) => {
            let percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            const _percentCompleted =
              percentCompleted > 95 ? 95 : percentCompleted;
            progressHandler(_percentCompleted);
            uploadingHandler(false);
          }
        )
        .then(({ data: dataResponse }) => {
          const { url } = dataResponse;

          clean(fileToUpload); //remove from cache
          //upload api is only for single file
          //TODO update it with multi file
          onFileChange(config.multiple ? url : [{ src: url }]);
          setTimeout(() => {
            progressHandler(100);
            uploadingHandler(false);
          }, 500);
        })
        .catch((err) => {
          console.log("uploadService err", err);
          uploadErrorHandler("Request Timeout")
          progressHandler(100);
          uploadingHandler(false);
        });
    }
  };

  const clean = (files) => {
    if (files) {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    }
  };
  const filePreviewSrc = (src) => {
    if (src.split(".").pop() === "pdf") {
      return "https://s3.us-west-2.amazonaws.com/pawnhero.ph/assets/PDF_file_icon.png";
    }
    return src;
  };

  return (
    <>
      <DropzoneStyle progress={progress}>
        <div className="file-container-wrapper ekyc-file-container-wrapper">
          <SimpleReactLightbox>
            <SRLWrapper
              callbacks={{
                onLightboxOpened: (object) => {
                  lighboxToggleHandler(true);
                },
                onLightboxClosed: (object) => {
                  lighboxToggleHandler(false);
                },
              }}
            >
              {files && !isEmpty(files) && !isNil(files) && (
                <>
                  {files.map((file, index) => (
                    <Fragment key={index}>
                      {file.src && file.src.split(".").pop() !== "pdf" && (
                        <>
                          <div className="kyc-image-wrapper ekyc-image-wrapper">
                            {fileRemovable && !config.disabled && !disabled_upload && (
                              <div
                                className="btn-remove"
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  zIndex: "99",
                                  display: "block",
                                  color: "#f14668",
                                }}
                                onClick={(e) => removeIconClick(file, index)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  size="lg"
                                  color="light"
                                />
                              </div>
                            )}
                            <img
                              className="uploaded-image"
                              src={filePreviewSrc(file.src)}
                              alt=""
                            />
                          </div>
                        </>
                      )}
                    </Fragment>
                  ))}
                </>
              )}
            </SRLWrapper>
          </SimpleReactLightbox>

          {files && !isEmpty(files) && !isNil(files) && (
            <>
              {files.map((file, index) => (
                <Fragment key={index}>
                  { file.src && file.src.split(".").pop() === "pdf" && (
                    <>
                      <div className="kyc-image-wrapper ekyc-image-wrapper">
                        {fileRemovable && !config.disabled && !disabled_upload && (
                          <div
                            className="btn-remove"
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              zIndex: "99",
                              display: "block",
                              color: "#f14668",
                            }}
                            onClick={(e) => removeIconClick(file, index)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              size="lg"
                              color="light"
                            />
                          </div>
                        )}
                        <img
                          className="uploaded-image"
                          src={filePreviewSrc(file.src)}
                          onClick={onClickUrl(file.src)}
                          alt=""
                        />
                      </div>
                    </>
                  )}
                </Fragment>
              ))}

              <div {...getRootProps({ className: "ekyc-uploader" })}>
              { !disabled_upload && 
                <>
                  <input {...getInputProps({ ...config })} />
                  <div className="add-upload">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </>
              }
              </div>
            </>
          )}
        </div>
        {!lighboxToggle && (
          <>
            {!uploading && isEmpty(files) && (
              <>
              { !disabled_upload && 
                <div {...getRootProps({ className: "ekyc-uploader" })}>
                  <input {...getInputProps({ ...config })} />
                  <FontAwesomeIcon icon={faUpload} /> {innerText}
                </div>
                }
              </>
            )}
          </>
        )}
        {progress > 0 && progress < 100 && (
          <div className="process-wrapper ekyc-loader">
            <div></div>
          </div>
        )}
        {uploadError && (
          <span style={{
            color: "#f14668",
          }}>
            <FontAwesomeIcon color='#f14668' icon={faExclamationCircle} bounce/>
            { uploadError }
          </span>
        )}
      </DropzoneStyle>
      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}
      >
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: "100%" }}>
              <Heading subtitle size={6}>
                Are you sure you want to delete this image?
              </Heading>
            </div>
          </Head>
          <Body>
            {stageRemoveFile && (
              <img
                src={(stageRemoveFile.file && filePreviewSrc(stageRemoveFile.file.src)) || ""}
                alt="Uploaded_Image"
                style={{
                  width: "100%",
                  maxWidth: "320px",
                  display: "block",
                  margin: "auto",
                }}
              />
            )}
          </Body>
          <Foot>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <button
                className="button is-primary"
                onClick={() => toggleHandler(false)}
              >
                Cancel
              </button>
              <button
                className="button is-danger"
                onClick={() => {
                  if (onRemove) {
                    onRemove(stageRemoveFile);
                    toggleHandler(false);
                  }
                }}
              >
                Remove
              </button>
            </div>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

export default MyDropZone;
