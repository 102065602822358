/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, useParams , useLocation} from 'react-router-dom';
import { pick } from '@ramda/pick/pick';
import { connect } from 'react-redux';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { getEmptyAttr, errorFieldHelperOptional } from 'utils/helper';
import { updateCustomerKyc, updateCustomerIdDetails } from 'actions/kyc';
import { fetchKycPersonalData } from "actions/kyc";
import { relationshipFields } from "components/customerForms/fields/relationship.json";
import {
  makeUpdateTicketReceiving,
  makeCancelReceiveTicket,
} from 'actions/tickets';
import { updateNotification } from 'actions/notification';
import { fetchCsStatus } from 'actions/support';
// components
import Layout from 'components/layout';
import {
  Columns,
  Button,
  Box,
  Form,
  Modal,
  Heading,
} from 'react-bulma-components';
import EkycForm from 'components/customerForms/ekycForm';
import PotentialMatchForm from 'components/customerForms/potentialMatchForm';
import PersonalForm from 'components/customerForms/ekycPersonalForm';
import PermanentAddressDetailsForm from 'components/customerForms/permanentAddressDetails';
import FinancialDetailsForm from 'components/customerForms/financialDetailsForm';
import RegulatoryRequirementsForm from 'components/customerForms/regulatoryRequirementsForm';
import ModalLoader from 'components/loader/modalLoader';
import ContentHeader from 'components/contentHeader';

const {
  Card: { Body, Foot, Head },
  Card,
} = Modal;
const { Column } = Columns;
const { Field } = Form;
const moment = require('moment');

const TicketReceivingForm = ({
  doMakeUpdateTicketReceiving,
  doUpdateCustomerKyc,
  doUpdateNotification,
  doUpdateCustomerIdDetails,
  doMakeCancelReceiveTicket,
  callback = false,
  doFetchCsStatus,
  doFetchKycPersonalData
}) => {
  const { ticketNo } = useParams();


  const history = useHistory();
  const [loading, loadingHandler] = useState(false);
  const [error, errorHandler] = useState(false);
  const [toggle, toggleHandler] = useState(false);
  const [ekycToggle, ekycToggleHandler] = useState(false);
  const [reason, reasonHandler] = useState('');
  const [reasonOthers, reasonOthersHandler] = useState('');

  const [status, statusHandler] = useState([]);
  
  const [provinces, provincesHandler] = useState([]);
  const [cities, citiesHandler] = useState([]);
  const [barangays, barangaysHandler] = useState([]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  //all fields are require
  const optionalFields = {
    personalFormData: [
      'mName',
      'landLine',
      'spouseLName',
      'spouseFName',
      'spouseMName',
      'spouseDateOfBirth',
      'spousePlaceOfBirth',
      'spouseOccupation',
      'customerRiskClass',
      'birthdate',
      'birthplace',
      'sssgsisNumber',
      'customerRiskClass',
      'birthdate',
      'birthplace',
      'sssgsisNumber',
      'tinIDNumber',
    ],
    employmentData: [
      'idRefNum',
      'yearInBusiness',
      'nameOfBusiness',
      'businessAddress',
      'businessLandline',
      'employersPhoneNumber',
      'business',
      // "sourceOfFunds",
      'nameOfEmployer',
      'natureOfBusiness',
      'occupation',
      // "employmentStatus",
      'yearWithEmployer',
      'positionRank',
      'grossMonthlyIncome',
      'employerAddress',
      'pawnTransactionPurpose',
      'isPoliticalRelationship',
      'isPawnheroEmployeeAffiliate',
      'phLName',
      'phFName',
      'phMName',
      'phSuffix',
      'phRelationship',
      'isBeneficialOwnership',
      'govt_id',
      'manualKycForm',
      'digiSignature',
      'othersSourceFunds',
      'othersEmploymentStatus',
      'otherNatureBusiness',
      'otherPawnTransactionPurpose',
    ],
  };

  const [errorFields, errorFieldsHandler] = useState({
    personalFormData: [],
    employmentData: [],
    financialData: [],
    addressData: [],
    pickupData: [],
    kycData: [],
    idData: [],
  });


  const [personalData, personalDataHandler] = useState({
    birthdate: "",
    birthplace: "",
    gender: "",
    kyc_image: "",
    nationality: {},
    potential_match: [],
    risk_type: "",
    user : {}
  });

  const [primaryDetails, primaryDetailsHandler] = useState({
    email: '',
    mobileNumber: '',
    fName: '',
    lName: '',
  })
  
  const [personalRefs, personalRefsHandler] = useState({});
  const [personalFormData, personalFormDataHandler] = useState({
    fName: '',
    mName: '',
    lName: '',
    nationality: '',
    birthDate: '',
    placeOfBirth: '',
    gender: '',
    kycFile: '',
    mobile: '',
    email: '',
    maritalStatus: '',
    citizenship: '',
    idType: '',
    idNumber: '',
    idValidity: '',
    tinIDNumber: '',
    sssgsisNumber: '',
    mobileNumber: '',
    landLine: '',
    spouseLName: '',
    spouseFName: '',
    spouseMName: '',
    spouseDateOfBirth: '',
    spousePlaceOfBirth: '',
    spouseOccupation: '',
  });

  const [employmentRefs, employmentRefsHandler] = useState({});
  const [employmentData, employmentDataHandler] = useState({
    sourceOfFunds: '',
    nameOfEmployer: '',
    natureOfBusiness: '',
    occupation: '',
    idRefNum: '',
    employmentStatus: '',
    yearWithEmployer: '',
    positionRank: '',
    grossMonthlyIncome: '',
    employerAddress: '',
    employersPhoneNumber: '',
    yearInBusiness: '',
    nameOfBusiness: '',
    businessAddress: '',
    businessLandline: '',
    pawnTransactionPurpose: '',
    isPoliticalRelationship: '',
    isPawnheroEmployeeAffiliate: '',
    phLName: '',
    phFName: '',
    phMName: '',
    phSuffix: '',
    phRelationship: '',
    isBeneficialOwnership: '',
    govt_id: '',
    manualKycForm: '',
    digiSignature: '',
    othersSourceFunds: '',
    othersEmploymentStatus: '',
    otherNatureBusiness: '',
    otherPawnTransactionPurpose: '',
  });


  const [relationFields, setRelationFields] = useState([relationshipFields]);
  const [politicalFields, setPoliticalFields] = useState([])

  const [politicalAffiliations, setPoliticalAffiliations] = useState({});

  const [isPoliticalRelationship, setIsPoliticalRelationship] = useState("no");
  const [customerRiskClass, setCustomerRiskClass] = useState("low");
  const [customerAutoRenewal, setCustomerAutoRenewal] = useState("false");
  const [isPawnheroEmployeeAffiliate, setIsPawnheroEmployeeAffiliate] =
    useState("no");
  const [isBeneficialOwnership, setIsBeneficialOwnership] = useState("no");


  const [
    regulatoryRequirementsDataRefs,
    regulatoryRequirementsDataRefsHandler,
  ] = useState({});
  const [regulatoryRequirementsData, regulatoryRequirementsDataHandler] =
    useState({
      politicalContainer: '',
      phLName: '',
      phFName: '',
      phMName: '',
      phSuffix: '',
      phRelationship: '',
      remarks: '',
      benificialOwnership: 'no',
      digiSignature: '',
      govtId: '',
      manualKycForm: '',
      customerRiskClass: 'low',
      customerAutoRenewal: false
    });

  const [addressRefs, addressRefHandler] = useState({});
  const [addressData, addressDataHandler] = useState({
    streetAddress: '',
    province: '',
    city: '',
    barangay: '',
    zipcode: '',
  });

  // const [pickupRefs, pickupRefsHandler] = useState({});
  // const [pickupData, pickupDataHandler] = useState({
  //   type: "Walk-in",
  //   streetAddress: "",
  //   province: "",
  //   city: "",
  //   barangay: "",
  //   dateReceive: "",
  //   rider: "",
  //   preferredDate: "",
  // });

  const [kycRefs, kycRefsHandler] = useState({});
  const [kycData, kycDataHandler] = useState({
    file: [],
  });

  const [idRef, idRefHandler] = useState({});
  const [idDetails, idDetailsHandler] = useState({
    govt_id: '',
    type_id: '',
    valid_until: '',
    govt_number: '',
  });

  const [ekycData, ekycDataHandler] = useState({
    itemName: '',
    itemModel: '',
    itemCategory: '',
    itemBrand: '',
    itemAttributes: [],
  });

  
  const [isDisabledPawnerPrimaryDetails, setIsDisabledPawnerPrimaryDetails] = useState(false);
  const [isDisabledEkycForm, setIsDisabledKisDisabledEkycForm] = useState(true);
  const [isDisabledPotentialMatch, setIsDisabledKisDisabledPotentialMatch] = useState(true);
  const [isFinalReviewPawner, setIsFinalReviewPawner] = useState(false);
  

  const getCsStatus = () => {
    //get cs status option
    doFetchCsStatus({ filter_by: 'ops_receiving' })
      .then((response) => {
        const { data, success } = response;
        if (success) {
          let _data = data
          const othersIndex = data.findIndex(item => item.reason === "Others");

          if (othersIndex !== -1) {
            // Remove the "Others" entry
            const othersEntry = data.splice(othersIndex, 1)[0];
            
            // Add the "Others" entry to the end of the array
            _data.push(othersEntry);
          }
          statusHandler(_data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const cancelTicket = (_payload) => {
    errorHandler(false);
    loadingHandler(true);

    doMakeCancelReceiveTicket(_payload)
      .then((data) => {
        const { success, message } = data;
        loadingHandler(false);
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'primary',
          });
          toggleHandler(false);
          ekycToggleHandler(false);
          if (callback) {
            callback();
          }
        } else {
          doUpdateNotification({
            toggle: true,
            message: message,
            color: 'danger',
          });
          errorHandler(
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
          );
        }
      })
      .catch((err) => {
        loadingHandler(false);
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
        errorHandler(
          'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
        );
      });
  };

  const saveKycCancelTicket = () => {
    const _payload = {
      ticket_no: ticketNo,
      reason: 'KYC issue',
    };
    // cancelTicket(_payload)
    // .then(() => makeUpdate(false))
    (async () => {
      await cancelTicket(_payload);
      await makeUpdate(false);
      loadingHandler(false);
      await doUpdateNotification({
        toggle: true,
        message: `${ticketNo} is successfully updated`,
        color: 'primary',
      });
      history.push('/tickets/receiving');
    })();
  };

  const doSubmit = () => {

    let _reason = reason
    if(reasonOthers !== "" || reasonOthers !== null){
      _reason = `${reason} - ${reasonOthers}`;
    }

    const _payload = {
      ticket_no: ticketNo,
      reason: _reason,
    };
    (async () => {
      await cancelTicket(_payload);
      loadingHandler(false);
      await doUpdateNotification({
        toggle: true,
        message: `${ticketNo} is successfully cancelled.`,
        color: 'primary',
      });
      history.push('/tickets/receiving');
    })();
  };

  const makeUpdate = async (for_ticket_receive = true) => {
    console.log('firing receiving update');
    loadingHandler(true);

    if (formValidate()) {
      console.log('formValidate', formValidate());
      loadingHandler(false);
    } else {
      const { streetAddress, barangay, city, province, zipcode } = addressData;
      console.log('employmentData', employmentData);
      const {
        nameOfEmployer,
        occupation,
        sourceOfFunds,
        // idRefNum,
        employmentStatus,
        natureOfBusiness,
        yearWithEmployer,
        positionRank,
        grossMonthlyIncome,
        employerAddress,
        employersPhoneNumber,
        yearInBusiness,
        nameOfBusiness,
        businessAddress,
        businessLandline,
        pawnTransactionPurpose,
        isPawnheroEmployeeAffiliate,
        othersSourceFunds,
        othersEmploymentStatus,
        otherNatureBusiness,
        otherPawnTransactionPurpose,
      } = employmentData;

      const {
        birthDate: birthdate,
        placeOfBirth,
        gender,
        // nationality: nationality_id,
        fName,
        lName,
        mName,
        maritalStatus,
        citizenship,
        idType,
        idNumber,
        idValidity,
        tinIDNumber,
        sssgsisNumber,
        // mobileNumber,
        landLine,
        // email,
        spouseLName,
        spouseFName,
        spouseMName,
        spouseDateOfBirth,
        spousePlaceOfBirth,
        spouseOccupation,
      } = personalFormData;

      const {
        politicalAffiliations,
        phLName,
        phFName,
        phMName,
        phSuffix,
        phRelationship,
        benificialOwnership,
        remarks,
        digiSignature,
        govtId,
        manualKycForm,
        customerRiskClass,
        customerAutoRenewal
      } = regulatoryRequirementsData;
      // const { file = [] } = kycData;

      // const {
      //   type,
      //   streetAddress: pAddress,
      //   barangay: pBarangay,
      //   city: pCity,
      //   province: pProvince,
      //   rider: rider_id,
      //   dateReceive: date_receive,
      //   preferredDate: preferred_date,
      // } = pickupData;

      // const { type_id, valid_until, govt_number } = idDetails;
      // const { itemName, itemModel, itemCategory, itemBrand, itemAttributes} = ekycData;
      const kycPayload = {
        ticket_no: ticketNo,
        customer_data: {
          admin_remarks: remarks,
          birthdate: birthdate,
          birthplace: placeOfBirth,
          gender,
          marital_status: maritalStatus,
          citizenship: citizenship,
          nationality_id: 1,
          type_id: idType,
          id_number: idNumber,
          govt_number: idNumber,
          valid_until: idValidity,
          tin_number: tinIDNumber,
          sss_number: sssgsisNumber,
          landline_number: landLine,
          spouse_first_name: spouseFName,
          spouse_last_name: spouseLName,
          spouse_middle_name: spouseMName,
          spouse_birthdate: spouseDateOfBirth,
          spouse_birthplace: spousePlaceOfBirth,
          spouse_occupation: spouseOccupation,
          address: streetAddress,
          barangay: barangay,
          city: city,
          province: province,
          zipcode: zipcode,
          fund_source: sourceOfFunds,
          employment_status: employmentStatus,
          employer: nameOfEmployer,
          business: natureOfBusiness,
          occupation_id: '',
          emp_years: yearWithEmployer,
          emp_occupation: occupation,
          emp_position: positionRank,
          emp_income: grossMonthlyIncome,
          emp_address: employerAddress,
          emp_phone_number: employersPhoneNumber,
          emp_business: natureOfBusiness,
          self_years_in_business: yearInBusiness,
          self_business_name: nameOfBusiness,
          self_income: grossMonthlyIncome,
          self_address: businessAddress,
          self_phone_number: businessLandline,
          self_business: natureOfBusiness,
          pawn_purpose: pawnTransactionPurpose,
          political_affiliations: !isEmpty(politicalAffiliations),
          political_affiliations_list: JSON.stringify([
            ...new Set(politicalAffiliations),
          ]),
          pawnhero_affiliations: isPawnheroEmployeeAffiliate,
          ph_last_name: phLName,
          ph_first: phFName,
          ph_middle_name: phMName,
          ph_suffix: phSuffix,
          ph_via: phRelationship,
          benificial_ownership: benificialOwnership,
          govt_id: JSON.stringify(govtId),
          kyc_image: JSON.stringify(manualKycForm),
          digital_signature: JSON.stringify(digiSignature),
          risk_type: customerRiskClass,
          other_employment_status: othersEmploymentStatus,
          other_source_of_funds: othersSourceFunds,
          other_nature_of_business: otherNatureBusiness,
          other_pawn_purpose: otherPawnTransactionPurpose,
        },
        user_data: {
          first_name: fName,
          last_name: lName,
          middle_name: mName,
          auto_renewal: customerAutoRenewal,
        },
      };
      console.log(kycPayload);

      // const idDetailsPayload = {
      //   id_details: {
      //     type_id,
      //     valid_until: moment(valid_until).format('YYYY-MM-DD 00:00:00'),
      //     govt_number,
      //   },
      //   ticket_no: ticketNo,
      // };

      try {
        // const { success: idUpdateSucess = false } =
        //   await doUpdateCustomerIdDetails(idDetailsPayload);
        const idUpdateSucess = true;
        if (idUpdateSucess) {
          const { message, success: updateSuccess } =
            await doUpdateCustomerKyc(kycPayload);

          if (updateSuccess) {
            if (for_ticket_receive) {
              const _payload = {
                ticket_no: ticketNo,
                params: {
                  status: 'AI',
                  date_receive: moment().format('YYYY-MM-DD HH:mm:ss'),
                },
              };
              const { message, success } =
                await doMakeUpdateTicketReceiving(_payload);

              if (success) {
                loadingHandler(false);
                doUpdateNotification({
                  toggle: true,
                  message: `${ticketNo} update successful`,
                  color: 'primary',
                });
                history.push('/tickets/receiving');
              } else {
                loadingHandler(false);
                doUpdateNotification({
                  toggle: true,
                  message: `${ticketNo}: ${message}`,
                  color: 'danger',
                });
                console.log('err on doMakeUpdateTicketReceiving ', message);
              }
            }
          } else {
            loadingHandler(false);
            doUpdateNotification({
              toggle: true,
              message: `Ticket Number ${ticketNo} : ${message}`,
              color: 'danger',
            });
            console.log('err on doUpdateCustomerKyc ', message);
          }
        } else {
          loadingHandler(false);
          doUpdateNotification({
            toggle: true,
            message: `${ticketNo} update id detail failed`,
            color: 'danger',
          });
        }
      } catch (err) {
        loadingHandler(false);
        console.log('API errors', err);
      }
    }
  };

  const formValidate = () => {
    let withError = false;

    //personal form
    const _personalIsEmpty = getEmptyAttr(personalFormData);
    const _personalErrFields = errorFieldHelperOptional(
      optionalFields.personalFormData,
      _personalIsEmpty
    ).filter((field) => field !== 'kycFile');

    //employment form
    const _employmentIsEmpty = getEmptyAttr(employmentData);
    const _employmentErrFields = errorFieldHelperOptional(
      optionalFields.employmentData,
      _employmentIsEmpty
    );

    //address form
    const _addressIsEmpty = getEmptyAttr(addressData);
    const _addressErrFields = errorFieldHelperOptional(
      optionalFields.addressData,
      _addressIsEmpty
    );

    //pickup form
    // const _pickupIsEmpty = getEmptyAttr(pickupData);
    // let _pickupErrFields = errorFieldHelperOptional(
    //   optionalFields.pickupData,
    //   _pickupIsEmpty
    // );

    // const { type } = pickupData;
    // const _pickupFields = [
    //   'type',
    //   'streetAddress',
    //   'province',
    //   'city',
    //   'barangay',
    //   'rider',
    //   'preferredDate',
    //   'dateReceive',
    // ];
    // const _walkinFields = ['type', 'dateReceive'];
    // if (type === 'Walk-in') {
    //   _pickupErrFields = intersection(_pickupErrFields, _walkinFields);
    // } else {
    //   _pickupErrFields = intersection(_pickupErrFields, _pickupFields);
    // }

    // kycform
    // const _kycIsEmpty = getEmptyAttr(kycData);
    // const _kycErrFields = errorFieldHelperOptional(
    //   optionalFields.kycData,
    //   _kycIsEmpty
    // );

    // idFields
    // const _idDetailsIsEmpty = getEmptyAttr(idDetails);
    // const _idErrFields = errorFieldHelperOptional(
    //   optionalFields.idData,
    //   _idDetailsIsEmpty
    // );

    withError =
      !isEmpty(_personalErrFields) ||
      !isEmpty(_employmentErrFields) ||
      !isEmpty(_addressErrFields)
        ? // !isEmpty(_pickupErrFields) ||
          // !isEmpty(_kycErrFields) ||
          // !isEmpty(_idErrFields)
          true
        : false;

    errorFieldsHandler({
      ...errorFields,
      personalFormData: _personalErrFields,
      employmentData: _employmentErrFields,
      addressData: _addressErrFields,
      // pickupData: _pickupErrFields,
      // kycData: _kycErrFields,
      // idData: _idErrFields,
    });
    console.log('errorFields', {
      ...errorFields,
      personalFormData: _personalErrFields,
      employmentData: _employmentErrFields,
      addressData: _addressErrFields,
      // pickupData: _pickupErrFields,
      // kycData: _kycErrFields,
      // idData: _idErrFields,
    });
    return withError;
  };

  const getPersonalData = async () => {
    doFetchKycPersonalData({ ticketNo })
      .then(({ data }) => {
        try {
          let result = { ...data };

        if (data.potential_match) {
          data.potential_match = data.potential_match.reduce((result, match) => {
            if (match.user_id !== data.user.user_id) {
              const { mobile_number, ...rest } = match;
              result.push({ ...rest, mobile: mobile_number });
            }
            return result;
          }, []);
        }

        personalDataHandler(result);
        } catch (err) {
          console.log("Error in personal data fetch:", err);
        }
      })
      .catch((err) => {
        console.log("Error in API call:", err);
      });
  };
  

  useEffect(() => {
    console.log("Updated personalData:", personalData);
  }, [personalData]);

  useEffect(() => {
    console.log("Updated personalFormData:", personalFormData);
  }, [personalFormData]);

  useEffect(() => {
    console.log("Updated addressData:", addressData);
  }, [addressData]);

  useEffect(() => {
    console.log("Updated Financial Data / Employment Data:", employmentData);
  }, [employmentData]);
  
  // useEffect(() => {
  //   console.log("Updated personalData:", personalData);
  // }, [personalData]);

  useEffect(() => {
    const {
      personalFormData,
      addressData,
      employmentData,
      kycData,
      // pickupData,
      idData,
    } = errorFields;

    if (personalFormData) {
      const _toFucosField = personalFormData[0];

      const _field = personalRefs[_toFucosField];

      if (_field) {
        const { current } = _field;
        if (_toFucosField === 'birthDate') {
          document.getElementById(_toFucosField).focus();
        } else {
          if (current) {
            current.focus();
          }
        }
      }
      return;
    }

    if (employmentData) {
      const _toFucosField = employmentData[0];
      const _field = employmentRefs[_toFucosField];

      if (_field) {
        const { current } = _field;
        if (current) {
          current.focus();
        }
      }
      return;
    }

    if (addressData) {
      const _toFucosField = addressData[0];
      const _field = addressRefs[_toFucosField];

      if (_field) {
        const { current } = _field;
        if (current) {
          current.focus();
        }
      }
      return;
    }

    // if (pickupData) {
    //   const _toFucosField = pickupData[0];
    //   const _field = pickupRefs[_toFucosField];

    //   if (_field) {
    //     const { current } = _field;
    //     if (current) {
    //       current.focus();
    //     }
    //   }
    //   return;
    // }

    if (kycData) {
      const _toFucosField = kycData[0];
      const _field = kycRefs[_toFucosField];

      if (_field) {
        const { current } = _field;
        if (current) {
          current.focus();
        }
      }
      return;
    }

    if (idData) {
      const _toFucosField = idData[0];
      const _field = idRef[_toFucosField];

      if (_field) {
        const { current } = _field;
        if (current) {
          current.focus();
        }
      }
      return;
    }

    // eslint-disable-next-line
  }, [errorFields]);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getPersonalData()
      getCsStatus();
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, []);
  
  return (
    <>
      <Layout>
        {loading && <ModalLoader />}
        <ContentHeader
          icon='/assets/menu-tickets-ico.svg'
          link='/'
          viewOnly={true}
          disabledFlags={true}
        />

        <Columns style={{ marginTop: '10px' }}>
          <Column size={6} className='d-grid'>
            <Box className='grid-1' style={{minHeight:"40rem"}}>
              <EkycForm
                data={primaryDetails}
                ekycData={ekycData}
                dataHandler={primaryDetailsHandler}
                ekycDataHandler={ekycDataHandler}
                kycDataHandler={kycDataHandler}
                idRefHandler={idRefHandler}
                errFields={errorFields.idData}
              />

              <Field style={{ marginTop: 0 }}>
                <Column
                  style={{
                    marginTop: '4rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Button
                    color='success'
                    onClick={() => {
                      setIsDisabledKisDisabledPotentialMatch(false)
                      setIsDisabledPawnerPrimaryDetails(true)
                      }}>
                      Review potential pawner matches
                  </Button>
                </Column>
              </Field>
              <Field style={{ marginTop: '-1.1rem' }}>
                <Column
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Button onClick={() => toggleHandler(true)} color='danger'>
                    Cancel pawn ticket
                  </Button>
                </Column>
              </Field>
            </Box>
            {isDisabledPawnerPrimaryDetails && (
              <>
                <Box
                  className='grid-1'
                  aria-disabled='true'
                  style={{ marginBottom: '1.5rem' }}>
                </Box>
              </>
            )}
          </Column>
          <Column size={6} className='d-grid'>
          { !isFinalReviewPawner && 
            <Box 
              style={{
                overflow: "scroll", 
                maxHeight:"40rem"
              }} 
              className={isDisabledPotentialMatch ? 'grid-1 form-box form-disabled' : 'grid-1 form-box'}>

              <div className={!isDisabledEkycForm ? "d-fadeout" : ""}>
                <PotentialMatchForm
                  data={idDetails}
                  ekycData={ekycData}
                  personalDataHandler={personalDataHandler}
                  personalData={personalData}
                  primaryDetailsHandler={primaryDetailsHandler}
                  personalFormDataHandler={personalFormDataHandler}
                  addressDataHandler={addressDataHandler}
                  ekycDataHandler={ekycDataHandler}
                  kycDataHandler={kycDataHandler}
                  idRefHandler={idRefHandler}
                  errFields={errorFields.idData}
                  doUpdateNotification={doUpdateNotification}
                  setIsDisabledKisDisabledEkycForm={setIsDisabledKisDisabledEkycForm}
                  setIsFinalReviewPawner={setIsFinalReviewPawner}
                  provincesHandler={provincesHandler}
                  citiesHandler={citiesHandler}
                  barangaysHandler={barangaysHandler}
                  employmentDataHandler={employmentDataHandler}
                  regulatoryRequirementsDataHandler={regulatoryRequirementsDataHandler}
                  setRelationFields={setRelationFields}
                  setPoliticalFields={setPoliticalFields}
                  setPoliticalAffiliations={setPoliticalAffiliations}
                  setIsPoliticalRelationship={setIsPoliticalRelationship}
                  setCustomerRiskClass={setCustomerRiskClass}
                  setCustomerAutoRenewal={setCustomerAutoRenewal}
                  setIsPawnheroEmployeeAffiliate={setIsPawnheroEmployeeAffiliate}
                  setIsBeneficialOwnership={setIsBeneficialOwnership}
                  relationFields={relationFields}
                />
                <Field style={{ marginTop: 0 }}>
                  <Column
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Button
                      color='success'
                      onClick={() => {
                        setIsDisabledKisDisabledEkycForm(false)
                        setTimeout(() => {
                          setIsFinalReviewPawner(true)
                        }, 100);
                      }}>
                      Review pawner details
                    </Button>
                  </Column>
                </Field>
                <Field style={{ marginTop: '-1.1rem' }}>
                  <Column
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Button onClick={() => toggleHandler(true)} color='danger'>
                      Cancel pawn ticket
                    </Button>
                  </Column>
                </Field>
              </div>
            </Box>
            }
            <Box className={isDisabledEkycForm ? 'grid-2 form-box form-disabled' : 'grid-2 form-box'}>
              <PersonalForm
                idData={idDetails}
                idDataHandler={idDetailsHandler}
                data={personalFormData}
                dataHandler={personalFormDataHandler}
                idRefHandler={idRefHandler}
                kycDataHandler={kycDataHandler}
                errFields={errorFields.personalFormData}
                personalRefsHandler={personalRefsHandler}
                idDetails
              />
              <PermanentAddressDetailsForm
                data={addressData}
                dataHandler={addressDataHandler}
                errFields={errorFields.addressData}
                addressRefHandler={addressRefHandler}
                provinces={provinces}
                cities={cities}
                barangays={barangays}
                provincesHandler={provincesHandler}
                citiesHandler={citiesHandler}
                barangaysHandler={barangaysHandler}
              />
              <FinancialDetailsForm
                data={employmentData}
                dataHandler={employmentDataHandler}
                errFields={errorFields.employmentData}
                employmentRefsHandler={employmentRefsHandler}
              />
              <RegulatoryRequirementsForm
                data={regulatoryRequirementsData}
                dataHandler={regulatoryRequirementsDataHandler}
                errFields={errorFields.regulatoryRequirementsData}
                regulatoryRequirementsDataRefs={
                  regulatoryRequirementsDataRefsHandler
                }
                setRelationFields={setRelationFields}
                setPoliticalFields={setPoliticalFields}
                setPoliticalAffiliations={setPoliticalAffiliations}
                setIsPoliticalRelationship={setIsPoliticalRelationship}
                setCustomerRiskClass={setCustomerRiskClass}
                setCustomerAutoRenewal={setCustomerAutoRenewal}
                setIsPawnheroEmployeeAffiliate={setIsPawnheroEmployeeAffiliate}
                setIsBeneficialOwnership={setIsBeneficialOwnership}
                relationFields={relationFields}
                politicalFields={politicalFields}
                politicalAffiliations={politicalAffiliations}
                isPoliticalRelationship={isPoliticalRelationship}
                customerRiskClass={customerRiskClass}
                customerAutoRenewal={customerAutoRenewal}
                isPawnheroEmployeeAffiliate={isPawnheroEmployeeAffiliate}
                isBeneficialOwnership={isBeneficialOwnership}
              />
              <Field style={{ marginTop: 0 }}>
                <Column
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Button onClick={() => makeUpdate()} color='success'>
                    Receive item for appraisal
                  </Button>
                </Column>
              </Field>

              <Field style={{ marginTop: '-1.1rem' }}>
                <Column
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Button
                    onClick={() => ekycToggleHandler(true)}
                    color='danger'>
                    Save KYC and cancel pawn ticket
                  </Button>
                </Column>
              </Field>
            </Box>
            {/* {isDisabledEkycForm && (
              <>
                <Box
                  className='grid-1 '
                  aria-disabled='true'
                  style={{ marginBottom: '1.5rem' }}></Box>
              </>
            )} */}
          </Column>
        </Columns>
      </Layout>

      <Modal
        show={toggle}
        onClose={() => toggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => toggleHandler(false)}>
            <div style={{ width: '100%' }}>Cancel this ticket : {ticketNo}</div>
          </Head>
          <Body>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <label style={{ marginRight: '10px' }}>Reason: </label>
              <select
                id='reason_cancel'
                name='reason_cancel'
                className='input'
                value={reason}
                onChange={(e) => {
                  reasonHandler(e.target.value);
                }}>
                <option hidden value=''>
                  Select a reason
                </option>
                {status.map((e, i) => (
                  <Fragment key={i}>
                    <option value={`(${e.id})_${e.reason}`}>{e.reason}</option>
                  </Fragment>
                ))}
              </select>
            </div>
            <div>
              {reason.substring(reason.lastIndexOf("_") + 1) === 'Others' && (
                <>
                  <div className='content-row'>
                    <p className='label'></p>
                    <textarea
                      style={{
                          resize: 'none',
                          width: '100%',
                          height: '150px',
                          border: '1px solid gray',
                          outline: 'none',
                          borderRadius: '5px',
                          padding: '5px',
                        }}
                      value={reasonOthers}
                      onChange={(e) => reasonOthersHandler(e.target.value)}
                    ></textarea>
                  </div>
                </>
              )}
            </div>
          </Body>
          <Foot>
            {error && <p className='error'>{error}</p>}
            <button
              className='button is-success'
              onClick={() => doSubmit()}
              disabled={
                isEmpty(reason) ||
                (reason.substring(reason.lastIndexOf(" ") + 1) === 'Others' && isEmpty(reasonOthers)) ||
                loading
              }>
              Submit
            </button>
            <button
              className='button is-warning'
              onClick={() => toggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>

      <Modal
        show={ekycToggle}
        onClose={() => ekycToggleHandler(false)}
        closeOnBlur={true}>
        <Card>
          <Head showClose={true} onClose={() => ekycToggleHandler(false)}>
            <div style={{ width: '100%' }}>Cancel this ticket : {ticketNo}</div>
          </Head>
          <Body>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <label style={{ marginRight: '10px' }}>Reason: </label>
              <select
                id='reason_cancel'
                name='reason_cancel'
                className='input'
                value={reason}
                onChange={(e) => {
                  reasonHandler(e.target.value);
                }}>
                <option hidden value=''>
                  Select a reason
                </option>
                {status.map((e, i) => (
                  <Fragment key={i}>
                    <option value={`${e.id}_${e.reason}`}>{e.reason}</option>
                  </Fragment>
                ))}
              </select>
            </div>
            <div>
              {reason.substring(reason.lastIndexOf("_") + 1) === 'Others' && (
                <>
                  <div className='content-row'>
                    <p className='label'></p>
                    <textarea
                      style={{
                          resize: 'none',
                          width: '100%',
                          height: '150px',
                          border: '1px solid gray',
                          outline: 'none',
                          borderRadius: '5px',
                          padding: '5px',
                        }}
                      value={reasonOthers}
                      onChange={(e) => reasonOthersHandler(e.target.value)}
                    ></textarea>
                  </div>
                </>
              )}
            </div>
          </Body>
          <Foot>
            {error && <p className='error'>{error}</p>}
            <button
              className='button is-success'
              onClick={() => doSubmit()}
              disabled={
                isEmpty(reason) ||
                (reason.substring(reason.lastIndexOf(" ") + 1) === 'Others' && isEmpty(reasonOthers)) ||
                loading
              }>
              Submit
            </button>
            <button
              className='button is-warning'
              onClick={() => ekycToggleHandler(false)}>
              Cancel
            </button>
          </Foot>
        </Card>
      </Modal>
    </>
  );
};

const mapStateToProps = pick([]);
const mapDispatchToProps = (dispatch) => ({
  doMakeCancelReceiveTicket: (payload) =>
    dispatch(makeCancelReceiveTicket(payload)),
  doMakeUpdateTicketReceiving: (ticket) =>
    dispatch(makeUpdateTicketReceiving(ticket)),
  doFetchKycPersonalData: (payload) => dispatch(fetchKycPersonalData(payload)),
  doUpdateCustomerKyc: (payload) => dispatch(updateCustomerKyc(payload)),
  doUpdateNotification: (notification) =>
    dispatch(updateNotification(notification)),
  doUpdateCustomerIdDetails: (payload) =>
    dispatch(updateCustomerIdDetails(payload)),
  doFetchCsStatus: (payload) => dispatch(fetchCsStatus(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketReceivingForm);
