import React, { Fragment, useEffect, useState } from 'react';
import numeral from 'numeral';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import {
  addItemImageTicket,
  makeCalculateLoanTicket,
  removeItemImageTicket,
  makePatchAutoRenewal
} from 'actions/tickets';
import { fetchKycPersonalData } from 'actions/kyc';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { pick } from '@ramda/pick/pick';
import { useParams } from 'react-router-dom';

// styles
import { ItemDataStyle } from 'styles/ticketForm.style';

// components
import MyDropZone from 'components/dropZone';
import PriceInput from 'components/shared/price';
import { titleCase } from 'title-case';
import RevertModal from './revertModal';
import { fetchCsStatus } from 'actions/support';
import { getElectronicList } from "utils/helper";
import { diff_dates } from 'utils/helper'
import { fetchTimelineTicket } from 'actions/tickets';

const ItemData = (props) => {
  const {
    user,
    data,
    dataHandler,
    callback,
    pageCallback,
    doAddItemImageTicket,
    doRemoveItemImageTicket,
    doMakeCalculateLoanTicket,
    doFetchCsStatus,
    doMakePatchAutoRenewal,
    hasSignedTicket,
    hasGovermentID,
    viewOnly = false,
    approval,
    lastUser,
    approvalLevel,
    generalData,
    isProcessing,
    support,
    doFetchKycPersonalData,
    doFetchTimelineTicket,
  } = props;

  const { ticketNo } = useParams();
  const { status_raw } = generalData;
  const [isRemoving, isRemovingHandler] = useState(false);
  const [itemId, itemIdHandler] = useState(false);
  const [value, valueHandler] = useState('');
  const [comment, commentHandler] = useState('');
  const [override, overrideHandler] = useState(true);
  const [autoRenewal, autoRenewalHandler] = useState(false);
  const [error, errorHandler] = useState(false);
  const [submitError, submitErrorHandler] = useState(false);
  const [isComputing, isComputingHandler] = useState(false);
  const [loanTable, loanTableHandler] = useState(null);
  const [calculatorError, calculatorErrorHandler] = useState(false);
  const [itemTab, itemTabHandler] = useState(0);
  const [hasPromo, hasPromoHandler] = useState(true);
  const [failedOptions, failedOptionsHandler] = useState('');
  const [reasonOthers, reasonOthersHandler] = useState('');

  const [_disableUpload, _disableUploadHandler] = useState(false);

  const inputLabels = [
    'Estimate',
    'Appraisal',
    'Approval',
    'Second Approval',
    'Final Approval',
  ];

  const loantableKeys = [
    { key: 'term_of_loan' },
    { key: 'appraised_value', format: '0,0.00' },
    { key: 'ratio_of_appraised_value', format: '0,0.000', suffix: '%' },
    { key: 'interest_amount', format: '0,0.00' },
    { key: 'service_charge', format: '0,0.00' },
    { key: 'dst_charge', format: '0,0.00' },
    { key: 'promo_discount', format: '0,0.00' },
    { key: 'customer_get', format: '0,0.00' },
    { key: 'loan_value', format: '0,0.00' },
    { key: 'interest_rate', format: '0.00%' },
  ];

  const conditionCheck = (itemImages) => {
    submitErrorHandler(false);
    let withError = false;
    const errorFields = [];
    //ctaButtonEnable => hasSignedTicket && hasGovermentID

    if (!hasSignedTicket) {
      errorFields.push('Please complete signed pawn ticket');
      withError = true;
    }

    if (!hasGovermentID) {
      errorFields.push('Please complete goverment ID');
      withError = true;
    }

    if (isEmpty(itemImages)) {
      errorFields.push('Please add Item image');
      withError = true;
    }

    if (!value) {
      errorFields.push('Please add Item Value');
      withError = true;
    }

    if (withError) {
      submitErrorHandler(errorFields);
    }

    return withError;
  };
  const updateticketAutoRenewal = async() => {
    errorHandler(false);
    let _payload = {
      ticket_no: ticketNo,
      auto_renewal : autoRenewal
    }
    const { message, success } = await doMakePatchAutoRenewal(_payload);
    if(success){
      errorHandler('Error updating auto renewal. Please call IT personnel.');
      console.log(message)
    }
  }
  const submitAppraisal = (data, estimatePrice, itemImages) => {
    errorHandler(false);

    if (!conditionCheck(itemImages)) {
      // no error

      if (value < 0) {
        errorHandler('Please enter correct amount');
      } else if (parseInt(estimatePrice) > value) {
        if (isEmpty(comment)) {
          errorHandler('Please select a comment');
        } else {
          callback(data);
        }
      } else {
        callback(data);
      }
    }
  };

  const onFileChange = async (file, existingfile, item_id) => {
    const _payload = {
      item_id,
      attrs: file.map((e) => ({
        image_url: e,
        thumbnail_url: e,
      })),
    };

    try {
      const { success, data: urls } = await doAddItemImageTicket(_payload);

      if (success) {
        const _newFile = [
          ...existingfile,
          ...urls.map((e) => ({ src: e.image, id: e.id })),
        ];

        const _data = data.map((e) => {
          if (e.item_id === item_id) {
            return { ...e, images: _newFile };
          } else {
            return e;
          }
        });

        dataHandler(update(data, { $merge: _data }));
      }
    } catch (err) {
      // uploadingHandler(false);
      console.log('err on upload', err);
    }
  };

  const onRemove = async (toRemoveFile, file, item_id) => {
    isRemovingHandler(true);

    const {
      file: { id },
    } = toRemoveFile;

    const payload = {
      item_image_id: id,
    };

    const { success } = await doRemoveItemImageTicket(payload);

    if (success) {
      //local remove
      const _file = file.filter((e) => e.id !== id);

      const _data = data.map((e) => {
        if (e.item_id === item_id) {
          return { ...e, images: _file };
        } else {
          return e;
        }
      });

      dataHandler(update(data, { $merge: _data }));
      isRemovingHandler(false);
    } else {
      isRemovingHandler(false);
    }
  };

  const calculator = async (payload) => {
    isComputingHandler(true);
    calculatorErrorHandler(false);

    const _payload = { ...payload, has_promo: hasPromo };

    try {
      const { data, success } = await doMakeCalculateLoanTicket(_payload);

      if (success) {
        loanTableHandler(data);
      }
      isComputingHandler(false);
    } catch (err) {
      isComputingHandler(false);
      console.log('error catch', err);
      calculatorErrorHandler(
        'Something went wrong. Please refresh the page, or contact PawnHero IT support.'
      );
    }
  };

  const getCsStatus = () => {
    //get cs status option
    let filter_by = 'ops_appraisal';
    if (
      data[0].appraisal_selling_price !== null ||
      data[0].appraisal_selling_price >= 0
    ) {
      filter_by = 'ops_approval';
    }
    doFetchCsStatus({ filter_by })
      .then((response) => {
        const { data, success } = response;

        if (success) {
          failedOptionsHandler(data);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const validateAutoRenewal = async () => {
    // doFetchKycPersonalData
    try {

      if(generalData?.autoRenewal && approvalLevel !== 0 && status_raw !== "IR"){
        autoRenewalHandler(generalData.auto_renewal);
      }else{
        const { data: _personalKycData, success } = await doFetchKycPersonalData({
          ticketNo,
        });
        if (success) {
          const autoRenewalValue = (_personalKycData?.user && approvalLevel === 0 && status_raw !== "IR")
            ? _personalKycData.user.auto_renewal
            : generalData.auto_renewal;
          
          autoRenewalHandler(autoRenewalValue);
        }
      }
    
    } catch (err) {
      console.log('err getKycData', err);
    }
  };
  const disabled_upload = async () => {
    const today = new Date (Date.now())
    const {
      data: { trails },
    } = await doFetchTimelineTicket(ticketNo);
    if (status_raw === "LR"){
      const lr_date = trails.find(el => el.activity_raw === "LR").inserted_at
      _disableUploadHandler(diff_dates(new Date(lr_date), today) >= 1)
      return
    }
    _disableUploadHandler(false)
    return
  }
  useEffect(() => {
    getCsStatus();
    validateAutoRenewal();
    disabled_upload()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let cancel = false;
    const timer = setTimeout(async () => {
      if (!cancel) {
        if (approval && !isEmpty(value)) {
          const _payload = {
            ticket_no: ticketNo,
            amount: value,
            item_id: itemId,
          };
          calculator(_payload);
        }

        if (
          (support || viewOnly) &&
          !cancel &&
          data.length &&
          data.length < 2
        ) {
          const { loan } = generalData;
          const { item_id } = data[0];
          const {
            estimate_selling_price,
            appraisal_selling_price,
            approval_selling_price,
            first_selling_price,
            second_selling_price,
          } = data[0];
          if (!isEmpty(loan) && typeof loan !== 'undefined' && loan != null) {
            hasPromoHandler(loan.has_promo);
          }
          const _payload = {
            ticket_no: ticketNo,
            amount:
              second_selling_price ||
              first_selling_price ||
              approval_selling_price ||
              appraisal_selling_price ||
              estimate_selling_price,
            item_id,
          };
          calculator(_payload);
        }
      }
    }, 500);

    return () => {
      clearTimeout(timer);
      cancel = true;
    };
    //eslint-disable-next-line
  }, [value, hasPromo]);

  return (
    <>
      <ItemDataStyle>
        <div className='tab-wrapper'>
          {data.map((e, index) => (
            <Fragment key={index}>
              <div
                className={`tab ${index === itemTab ? 'active' : ''}`}
                onClick={() => {
                  itemTabHandler(index);
                }}>
                <p>{e.name}</p>
              </div>
            </Fragment>
          ))}

          <div className='empty-tab' />
        </div>
        <div className='tab-content-wrapper'>
          {data.map((e, index) => {
            const { appraisal = false, attributes } = e;

            const _images = e.images.map((el) => ({
              src: el.thumb || el.image || el.src,
              id: el.id,
            }));

            const {
              estimate_selling_price: estimatePrice,
              appraisal_selling_price: appraisalPrice,
              approval_selling_price: approvalPrice,
              first_selling_price: fistPrice,
              second_selling_price: secondPrice,
            } = e;

            const _appraisal = appraisal.filter(
              (e) => e.appraised_by_id !== 0 || e.appraisal_type === 'estimate'
            );

            return (
              <Fragment key={index}>
                <div
                  className={`tab-content ${index === itemTab ? 'show' : ''}`}>
                  <div className='column is-12 dropzone-wrapper'>
                    <MyDropZone
                      files={_images}
                      config={{
                        multiple: true,
                        disabled: (viewOnly || approval) && !support,
                        disable_upload: _disableUpload.toString()
                      }}
                      onFileChange={(newFile) => {
                        onFileChange(newFile, _images, e.item_id);
                      }}
                      fileRemovable={(!_disableUpload).toString()}
                      onRemove={(toRemoveFile) =>
                        onRemove(toRemoveFile, _images, e.item_id)
                      }
                      isLoading={isRemoving}
                    />
                  </div>
                  <div className='columns info-container-wrapper'>
                    <div className='column is-6'>
                      <div className='items-info-wrapper'>
                        <div className='content'>
                          <p className='sub-title'>Pawn Item</p>
                          <p className='value'>{e.name.split('-')[1]}</p>
                        </div>
                        <div className='content'>
                          <p className='sub-title'>Item Category</p>
                          <p className='value'>{e.name.split('-')[0]}</p>
                        </div>
                        {attributes
                          .sort((a, b) => a.selected.length - b.selected.length)
                          .map((attr, key) => (
                            <Fragment key={key}>
                              <div className='content'>
                                <p className='sub-title'>
                                  {attr.attribute_name}
                                </p>
                                <p className='value'>
                                  {attr.selected.map((sel, key) => (
                                    <Fragment key={key}>
                                      <span>{sel}</span>
                                    </Fragment>
                                  ))}
                                </p>
                              </div>
                            </Fragment>
                          ))}
                      </div>
                    </div>
                    <div className='column is-6'>
                      <div className='appraisal-info-wrapper'>
                        {_appraisal && (
                          <>
                            {_appraisal.map((appraise, i) => {
                              let displayAmount = 0;
                              let displayAppraisalType = '';
                              const revertStatus = [
                                'OS',
                                'OA',
                                'PS',
                                'FA',
                                'FI',
                                'FT',
                                'SA',
                                'SP',
                                'SS',
                              ];
                              const { appraisal_amount, appraisal_type } =
                                appraise;
                              displayAppraisalType = appraisal_type;
                              let showRevert = false;
                              if (appraise.appraisal_type === 'estimate') {
                                displayAmount = estimatePrice
                                  ? estimatePrice
                                  : 0;
                              }
                              if (appraise.appraisal_type === 'appraisal') {
                                displayAmount = appraisalPrice
                                  ? appraisalPrice
                                  : 0;
                              }
                              if (appraise.appraisal_type === 'approval') {
                                displayAmount = approvalPrice
                                  ? approvalPrice
                                  : 0;
                                showRevert = revertStatus.includes(status_raw)
                                  ? true
                                  : false;
                              }
                              if (appraise.appraisal_type === 'first') {
                                displayAmount = fistPrice ? fistPrice : 0;
                                displayAppraisalType = 'Second Approval';
                                showRevert = revertStatus.includes(status_raw)
                                  ? true
                                  : false;
                              }
                              if (appraise.appraisal_type === 'second') {
                                displayAmount = secondPrice ? secondPrice : 0;
                                displayAppraisalType = 'Final Approval';
                                showRevert = revertStatus.includes(status_raw)
                                  ? true
                                  : false;
                              }
                              return (
                                <Fragment key={i}>
                                  <div className='content-row'>
                                    <div>
                                      <div>
                                        <p
                                          className='sub-title'
                                          style={{ fontStyle: 'italic' }}>
                                          {titleCase(displayAppraisalType)}{' '}
                                          <span
                                            style={{
                                              color: '#327FBE',
                                            }}>
                                            ({appraise.appraised_by})
                                          </span>
                                        </p>
                                        <p className='appraised-amount'>
                                          ₱{' '}
                                          {numeral(displayAmount).format(
                                            '0,0.00'
                                          )}
                                        </p>
                                      </div>                                  

                                      {(viewOnly || support) && (
                                        <>
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'space-between',
                                            }}>
                                            <div>
                                              <p
                                                className='sub-title'
                                                style={{ fontStyle: 'italic' }}>
                                                Loan Value
                                              </p>
                                              <p className='appraised-amount'>
                                                ₱{' '}
                                                {numeral(
                                                  appraisal_amount
                                                ).format('0,0.00')}
                                              </p>
                                            </div>
                                            <div>
                                              {_appraisal.length === i + 1 &&
                                                showRevert && (
                                                  <RevertModal
                                                    pageCallback={pageCallback}
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    <p
                                      className='note'
                                      style={{ marginTop: '5px' }}>
                                      {appraise.appraised_datetime || ''}
                                    </p>
                                  </div>
                                </Fragment>
                              );
                            })}
                          </>
                        )}

                        {!viewOnly && !lastUser.includes(user.userId) && (
                          <>
                            <div className='content-row'>
                              <p className='sub-title'>
                                {titleCase(
                                  inputLabels[_appraisal.length] ||
                                    'Appraisal Value'
                                )}
                              </p>
                              <PriceInput
                                className='input'
                                disableAbbreviations={true}
                                decimalsLimit={4}
                                value={value}
                                // allowNegativeValue={false}
                                onValueChange={(value) => {
                                  valueHandler(value);
                                  commentHandler('');
                                  itemIdHandler(e.item_id);
                                }}
                              />
                            </div>

                            {approval && loanTable && value && (
                              <>
                                {/* <div
                                  className={`content-row ${
                                    value >= 20000
                                      ? ''
                                      : 'is-hidden'
                                  }`}>
                                  <input
                                    id='has_promo'
                                    type='checkbox'
                                    value='hasPromo'
                                    onChange={() => hasPromoHandler(!hasPromo)}
                                    checked={hasPromo}
                                  />
                                  <label htmlFor='hasPromo'> APPLY PROMO</label>
                                </div> */}
                                <div className='content-row flex'>
                                  <div className='sub-content'>
                                    <p className='sub-title'>
                                      Loan Value to Appraised Value Ratio
                                    </p>
                                    <p
                                      className={`value  ${
                                        loanTable.find(
                                          (e) =>
                                            e.term_of_loan ===
                                            generalData.loan_duration
                                        ).ratio_of_appraised_value === 0
                                          ? 'danger'
                                          : 'primary'
                                      } `}>
                                      {
                                        loanTable.find(
                                          (e) =>
                                            e.term_of_loan ===
                                            generalData.loan_duration
                                        ).ratio_of_appraised_value
                                      }{' '}
                                      %
                                    </p>
                                  </div>
                                  <div className='sub-content'>
                                    <p className='sub-title'>Loan Value</p>
                                    <p className='value'>
                                      ₱{' '}
                                      {numeral(
                                        loanTable.find(
                                          (e) =>
                                            e.term_of_loan ===
                                            generalData.loan_duration
                                        ).loan_value
                                      ).format('0,0.00')}
                                    </p>
                                  </div>
                                </div>
                                <div className='content-row flex'>
                                  <div className='sub-content'>
                                    <p className='sub-title'>Loan Term</p>
                                    <p className='value'>
                                      {
                                        loanTable.find(
                                          (e) =>
                                            e.term_of_loan ===
                                            generalData.loan_duration
                                        ).term_of_loan
                                      }{' '}
                                      month(s)
                                    </p>
                                  </div>
                                  <div className='sub-content'>
                                    <p className='sub-title'>Interest Income</p>
                                    <p className='value'>
                                      ₱{' '}
                                      {numeral(
                                        loanTable.find(
                                          (e) =>
                                            e.term_of_loan ===
                                            generalData.loan_duration
                                        ).interest_amount
                                      ).format('0,0.00')}
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                              {(approvalLevel === 0) && (status_raw !== "IR") && !getElectronicList().includes(data[0].category) && (
                                <div className='content-row'>
                                  <input
                                    id='auto_renewal'
                                    type='checkbox'
                                    value='auto_renewal'
                                    onChange={() => autoRenewalHandler(!autoRenewal)}
                                    checked={autoRenewal}
                                  />
                                  <label htmlFor='auto_renewal'> Auto Renewal</label>
                                </div>
                              )}
                              
                              {approvalLevel === 1 && (
                                <div className='content-row'>
                                  <input
                                    id='override'
                                    type='checkbox'
                                    value='override'
                                    onChange={() => overrideHandler(!override)}
                                    checked={override}
                                  />
                                  <label htmlFor='override' style={{ marginRight: '10px' }}> Override</label>
                                  {!getElectronicList().includes(data[0].category) && (
                                    <>
                                      <input
                                        id='auto_renewal'
                                        type='checkbox'
                                        value='auto_renewal'
                                        onChange={() => autoRenewalHandler(!autoRenewal)}
                                        checked={autoRenewal}
                                      />
                                      <label htmlFor='auto_renewal'> Auto Renewal</label>
                                    </>
                                  )}
                                </div>
                              )}
                            {value !== '' &&
                              parseInt(estimatePrice) > value &&
                              value <= 0 && (
                                <div className='content-row'>
                                  <p className='sub-title'>
                                    Cancellation Reason
                                  </p>
                                  <select
                                    className='input'
                                    value={comment}
                                    onChange={(e) => {
                                      commentHandler(e.target.value);
                                    }}>
                                    <option value='' hidden=''>
                                      Please select a reason
                                    </option>
                                    {failedOptions.map((e, i) => (
                                      <option
                                        key={i}
                                        value={`${e.id}_${e.reason}`}>
                                        {e.reason}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            {value !== '' &&
                              parseInt(estimatePrice) > value &&
                              value > 0 && (
                                <div className='content-row'>
                                  <p className='sub-title'>Comment</p>
                                  <select
                                    className='input'
                                    value={comment}
                                    onChange={(e) => {
                                      commentHandler(e.target.value);
                                    }}>
                                    <option value='' hidden=''>
                                      Please select a reason
                                    </option>
                                    <option value='Did not pass our authenticity test'>
                                      Did not pass our authenticity test
                                    </option>
                                    <option value='Did not pass our Aesthetic test'>
                                      Did not pass our Aesthetic test
                                    </option>
                                    <option value='Functionality concern'>
                                      Functionality concern
                                    </option>
                                    <option value='Usability issues'>
                                      Usability issues
                                    </option>
                                    <option value='Item received is different from the initially described'>
                                      Item received is different from the
                                      initially described
                                    </option>
                                    <option value='Others'>Others</option>
                                  </select>
                                </div>
                              )}
                            {comment.substring(comment.lastIndexOf('_') + 1) ===
                              'Others' && (
                              <>
                                <div className='content-row'>
                                  <p className='label'></p>
                                  <textarea
                                    style={{
                                      resize: 'none',
                                      width: '100%',
                                      height: '150px',
                                      border: '1px solid gray',
                                      outline: 'none',
                                      borderRadius: '5px',
                                      padding: '5px',
                                    }}
                                    value={reasonOthers}
                                    onChange={(e) =>
                                      reasonOthersHandler(e.target.value)
                                    }></textarea>
                                </div>
                              </>
                            )}
                            {error && <p className='error'>{error}</p>}
                            {submitError && (
                              <>
                                {submitError.map((err, i) => (
                                  <p className='error' key={i}>
                                    {err}
                                  </p>
                                ))}
                              </>
                            )}
                            <button
                              className='button is-success cta-submit-appraisal'
                              onClick={async () => {
                                if(status_raw !== "IR"){
                                  await updateticketAutoRenewal();
                                }
                                await submitAppraisal(
                                  {
                                    [e.item_id]: {
                                      hasPromo,
                                      value,
                                      comment: `${comment}_${reasonOthers}`,
                                      file: e.images,
                                      // override: false,
                                      override:
                                        approvalLevel === 1 ? override : false,
                                    },
                                  },

                                  estimatePrice,
                                  e.images
                                );
                              }}
                              disabled={
                                // !ctaButtonEnable ||
                                // isEmpty(e.images) ||
                                // !value ||
                                isProcessing
                              }>
                              Submit appraisal
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {(value || approval || viewOnly || support) && loanTable && (
                    <>
                      {isComputing ? (
                        <>
                          <p>loading...</p>
                        </>
                      ) : (
                        <>
                          <div className='value-strip'>
                            <div>
                              <div>
                                <p className='title'>Total Appraised Value</p>
                                <p className='value'>
                                  ₱{' '}
                                  {numeral(
                                    loanTable.find(
                                      (e) =>
                                        e.term_of_loan ===
                                        generalData.loan_duration
                                    ).appraised_value || ''
                                  ).format('0,0.00')}
                                </p>
                              </div>
                              <div>
                                <p className='title'>Loan Value</p>
                                <p className='value'>
                                  ₱{' '}
                                  {numeral(
                                    loanTable.find(
                                      (e) =>
                                        e.term_of_loan ===
                                        generalData.loan_duration
                                    ).loan_value || ''
                                  ).format('0,0.00')}
                                </p>
                              </div>
                              <div>
                                <p className='title'>Total Interest Income</p>
                                <p className='value'>
                                  ₱{' '}
                                  {numeral(
                                    loanTable.find(
                                      (e) =>
                                        e.term_of_loan ===
                                        generalData.loan_duration
                                    ).interest_amount || ''
                                  ).format('0,0.00')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='column is-12 loan-table-wrapper'>
                            <div className='table-wrapper'>
                              <table className='table-header'>
                                <thead>
                                  <tr>
                                    <th>Loan Term</th>
                                  </tr>
                                  <tr>
                                    <th>Appraised Value</th>
                                  </tr>
                                  <tr>
                                    <th>Appraised Value Ratio</th>
                                  </tr>
                                  <tr>
                                    <th>Total Interest</th>
                                  </tr>
                                  <tr>
                                    <th>Service Charge</th>
                                  </tr>
                                  <tr>
                                    <th>DST Charge</th>
                                  </tr>
                                  <tr>
                                    <th>Promo Discount</th>
                                  </tr>
                                  <tr>
                                    <th>Customer Gets</th>
                                  </tr>
                                  <tr>
                                    <th>Pays Back</th>
                                  </tr>
                                  <tr>
                                    <th>Interest Rate</th>
                                  </tr>
                                </thead>
                              </table>
                              <div className='table-body-wrapper'>
                                <table className='table-body'>
                                  <tbody>
                                    {loantableKeys.map((key, index) => (
                                      <Fragment key={index}>
                                        <tr>
                                          {loanTable.map((lt, rindex) => {
                                            const _color =
                                              lt.customer_get < 0
                                                ? '#e2574c'
                                                : null;
                                            return (
                                              <Fragment key={rindex}>
                                                <td
                                                  style={{
                                                    color: _color,
                                                  }}>
                                                  {key.format ? (
                                                    <>
                                                      {numeral(
                                                        lt[key.key]
                                                      ).format(key.format)}
                                                    </>
                                                  ) : (
                                                    <>{lt[key.key]}</>
                                                  )}
                                                  {key.suffix && (
                                                    <>{key.suffix}</>
                                                  )}
                                                </td>
                                              </Fragment>
                                            );
                                          })}
                                        </tr>
                                      </Fragment>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {calculatorError && (
                    <p className='error' style={{ padding: '0 20px' }}>
                      {calculatorError}
                    </p>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </ItemDataStyle>
    </>
  );
};

const mapStateToProps = pick(['user']);
const mapDispatchToProps = (dispatch) => ({
  doAddItemImageTicket: (payload) => dispatch(addItemImageTicket(payload)),
  doRemoveItemImageTicket: (payload) =>
    dispatch(removeItemImageTicket(payload)),
  doMakeCalculateLoanTicket: (payload) =>
    dispatch(makeCalculateLoanTicket(payload)),
  doFetchCsStatus: (payload) => dispatch(fetchCsStatus(payload)), //cs modal dropdown option
  doMakePatchAutoRenewal: (payload) => dispatch(makePatchAutoRenewal(payload)), //cs modal dropdown option
  doFetchKycPersonalData: (payload) => dispatch(fetchKycPersonalData(payload)),
  doFetchTimelineTicket: (ticketNo) => dispatch(fetchTimelineTicket(ticketNo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemData);
