import React, { useEffect, useState } from 'react';
import {
  fetchCustomerGovernmentId,
  fetchIdTypes,
  updateCustomerGovernmentId,
  updateCustomerIdDetails,
} from 'actions/kyc';
import MyDropZone from 'components/dropZone/dropZone-v2';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isNil } from '@ramda/isnil/isNil';
import { isEmpty } from '@ramda/isempty/isEmpty';
import { Fragment } from 'react';
import { updateNotification } from 'actions/notification';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import { makeTicketFileLog } from 'actions/tickets';

const moment = require('moment');

const GovernmentId = (props) => {
  const {
    doFetchCustomerGovernmentId,
    doUpdateCustomerGovernmentId,
    governmentIDCallback,
    viewOnly,
    doFetchIdTypes,
    doUpdateCustomerIdDetails,
    doUpdateNotification,
    doMakeTicketFileLog,
    disable_upload
  } = props;

  const { ticketNo } = useParams();

  const [isLoading, isLoadingHandler] = useState(true);
  const [govermentId, govermentIdHandler] = useState(null);
  const [oldGovermentId, oldGovermentIdHandler] = useState(null);
  const [idOptions, idOptionsHandler] = useState([]);
  const [isLoadingOptions, isLoadingOptionsHandler] = useState(true);
  const [formData, formDataHandler] = useState({});

  const getData = async () => {
    isLoadingHandler(true);

    try {
      const {
        success,
        data: { govt_id, type_id, valid_until, govt_number },
      } = await doFetchCustomerGovernmentId({ ticketNo });

      if (success) {
        const _govtID =
          govt_id && govt_id !== 'NULL' ? JSON.parse(govt_id) : false;
        if (_govtID) {
          const _id = _govtID.map((e) => {
            return { src: e.src || e.thumb, img: e.src };
          });

          govermentIdHandler(_id);
          oldGovermentIdHandler(_id);
        } else {
          govermentIdHandler([]);
          oldGovermentIdHandler([]);
        }

        formDataHandler({
          type_id: type_id || '',
          valid_until: valid_until ? moment(valid_until).toDate() : '',
          govt_number: govt_number || '',
        });
        isLoadingHandler(false);
      } else {
        govermentIdHandler(false);
        oldGovermentIdHandler([]);
        isLoadingHandler(false);
      }
    } catch (err) {
      console.log('err', err);
      isLoadingHandler(false);
      govermentIdHandler(false);
      oldGovermentIdHandler([]);
    }
  };

  const compareArrays = async (old_item, new_item) => {
    const added = new_item.filter(item => !old_item.find(({ src }) => src === item.src));
    const removed = old_item.filter(item => !new_item.find(({ src }) => src === item.src));
    return { added, removed };
  }
  

  const addTrailOnFileChange = async (govt_id) => {
    const { added, removed } = await compareArrays(oldGovermentId, govt_id);
    let message = "";
    if (!isNil(added) && !isEmpty(added)){
      message += `Uploaded Government Document : ${added.map(item => item.src).join(",")}. `;
    }
    if (!isNil(removed) && !isEmpty(removed)){
      message += `Removed Government Document : ${removed.map(item => item.src).join(",")}. `;
    }
    const payload = {
      ticket_no: ticketNo,
      comment: message,
      attachment: "",
    };
    doMakeTicketFileLog(payload)
      .then((response) => {
        // const { success, message } = response;
        oldGovermentIdHandler(govt_id)
      })
      .catch(
        ({
          data: {
            message = 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          },
        }) => {
          doUpdateNotification({
            toggle: true,
            message,
            color: 'danger',
          });
        }
      );
  }

  const onFileChange = async (newFile, id) => {
    const src = newFile[0].src;
    const _govtID = [...id, { src, img: src }];

    const _end = moment().endOf('year').format('YYYY-MM-DD HH:mm:ss');

    const payload = {
      ticket_no: ticketNo,
      customer_data: {
        govt_id: JSON.stringify(_govtID),
        valid_until: _end,
      },
    };

    const { success } = await doUpdateCustomerGovernmentId(payload);

    if (success) {
      govermentIdHandler(_govtID);
      addTrailOnFileChange(_govtID)
    }
  };

  const onRemove = async (fileToRemove, id) => {
    const {
      file: { src },
    } = fileToRemove;
    const _id = id.filter((e) => e.src !== src);

    const _end = moment().endOf('year').format('YYYY-MM-DD HH:mm:ss');
    const payload = {
      ticket_no: ticketNo,
      customer_data: {
        govt_id: JSON.stringify(_id),
        valid_until: _end,
      },
    };

    const { success } = await doUpdateCustomerGovernmentId(payload);

    if (success) {
      govermentIdHandler(_id);
      addTrailOnFileChange(_id)
    }
  };

  const getIdOptions = () => {
    isLoadingOptionsHandler(true);
    doFetchIdTypes()
      .then(({ data, success }) => {
        if (success) {
          idOptionsHandler(data);
        }
        isLoadingOptionsHandler(false);
      })
      .catch((err) => {
        console.log('err', err);
        isLoadingOptionsHandler(false);
      });
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getData();
      getIdOptions();
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      if (govermentId && !isNil(govermentId) && !isEmpty(govermentId)) {
        governmentIDCallback(true);
      } else {
        governmentIDCallback(false);
      }
    }

    return () => {
      cancel = true;
    };

    //eslint-disable-next-line
  }, [govermentId]);

  const fireUpdateIdDetails = () => {
    const { type_id, valid_until, govt_number } = formData;

    const _payload = {
      id_details: {
        type_id,
        valid_until: moment(valid_until).format('YYYY-MM-DD 00:00:00'),
        govt_number,
      },
      ticket_no: ticketNo,
    };

    doUpdateCustomerIdDetails(_payload)
      .then((response) => {
        const { success } = response;
        if (success) {
          doUpdateNotification({
            toggle: true,
            message: `successfully update customer ID info`,
            color: 'primary',
          });
        } else {
          doUpdateNotification({
            toggle: true,
            message:
              'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
            color: 'danger',
          });
        }
      })
      .catch((err) => {
        doUpdateNotification({
          toggle: true,
          message:
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };
  return (
    <>
      {isLoading ? (
        <>
          <p>loading...</p>
        </>
      ) : (
        <>
          {govermentId === false ? (
            <>
              <p className='error'>
                Something went wrong. Please refresh the page, or contact
                PawnHero IT support.
              </p>
            </>
          ) : (
            <>
              <MyDropZone
                message={`Drag 'n' drop some files here, or click to select files`}
                config={{
                  multiple: false,
                  disabled: viewOnly,
                  disable_upload: disable_upload
                }}
                onFileChange={(newFile) => onFileChange(newFile, govermentId)}
                files={govermentId}
                fileRemovable={true}
                onRemove={(fileToRemove) => {
                  onRemove(fileToRemove, govermentId);
                }}
              />

              <p className='value' style={{ margin: '10px 0 10px' }}>
                Government ID details
              </p>

              {isLoadingOptions ? (
                <p>loading</p>
              ) : (
                <>
                  <p className='sub-title'>ID type</p>
                  <select
                    className='input'
                    style={{ margin: '0 0 10px' }}
                    disabled={viewOnly}
                    value={formData.type_id}
                    onChange={(e) =>
                      formDataHandler({ ...formData, type_id: e.target.value })
                    }>
                    <option value=''>SELECT ID</option>
                    {idOptions.map((id, i) => (
                      <Fragment key={i}>
                        <option value={id.id} disabled={!id.is_active}>
                          {id.name}
                        </option>
                      </Fragment>
                    ))}
                  </select>
                </>
              )}

              <p className='sub-title'>ID number</p>
              <input
                type='text'
                className='input'
                style={{ margin: '0 0 10px' }}
                disabled={viewOnly}
                value={formData.govt_number}
                onChange={(e) =>
                  formDataHandler({ ...formData, govt_number: e.target.value })
                }
              />

              <p className='sub-title'>Valid until</p>

              <SimpleDatePicker
                className='input'
                disabled={viewOnly}
                value={formData.valid_until}
                onChange={(e) =>
                  formDataHandler({ ...formData, valid_until: e })
                }
              />
              <button
                className='button is-success full-width'
                style={{ margin: '10px auto', display: 'block' }}
                onClick={() => fireUpdateIdDetails()}
                disabled={
                  isEmpty(formData.type_id) ||
                  isEmpty(formData.govt_number) ||
                  isEmpty(formData.valid_until) ||
                  viewOnly
                }>
                Submit ID Details
              </button>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchCustomerGovernmentId: (ticketNo) =>
    dispatch(fetchCustomerGovernmentId(ticketNo)),
  doUpdateCustomerGovernmentId: (payload) =>
    dispatch(updateCustomerGovernmentId(payload)),
  doFetchIdTypes: () => dispatch(fetchIdTypes()),
  doUpdateCustomerIdDetails: (payload) =>
    dispatch(updateCustomerIdDetails(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doMakeTicketFileLog: (payload) => dispatch(makeTicketFileLog(payload)),
});
export default connect(null, mapDispatchToProps)(GovernmentId);
