import { isEmpty } from '@ramda/isempty/isEmpty';
// import { isNil } from '@ramda/isnil/isNil';
import { fetchCoinsBank } from 'actions/coins';
import { fetchBarangays, fetchCities, fetchProvinces } from 'actions/location';
import { updateNotification } from 'actions/notification';
import { fetchRiders } from 'actions/rider';
import { makeUpdateTicketInfo, fetchTimelineTicket, } from 'actions/tickets';
import { SimpleDatePicker } from 'components/shared/simpleDatePicker';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { TicketDataStyle } from 'styles/ticketForm.style';
import Barcode from './barcode';
import TicketPhoto from './ticketPhoto';
import Select from 'react-select';
// import { diff_dates } from 'utils/helper'

const moment = require('moment');

const SupportTicketData = ({
  data,
  signedTicketCallback,
  doFetchProvinces,
  doFetchCities,
  doFetchBarangays,
  doFetchRiders,
  doMakeUpdateTicketInfo,
  doUpdateNotification,
  doFetchCoinsBank,
  doFetchTimelineTicket,
}) => {
  const { ticketNo } = useParams();
  const location = useLocation();
  const { generalData, locationData, barcodeText } = data;
  const [errors, setErrors] = useState({
    address: '',
    province: '',
    city: '',
    barangay: ''
  });
  const [formData, formDataHandler] = useState({
    ticket_no: generalData.ticket_no,
    type: locationData.type ? locationData.type.toLowerCase() : '',
    address: locationData.address,
    province: locationData.province ? locationData.province.id : '',
    city: locationData.city ? locationData.city.id : '',
    barangay: locationData.barangay ? locationData.barangay.id : '',
    rider: locationData.rider || '',
    preferred_date: moment(locationData.preferred_date).format('YYYY-MM-DD'),
    payment_method_type:
      generalData &&
      generalData.payment_method &&
      generalData.payment_method.type_raw
        ? generalData.payment_method.type_raw
        : '',
    payment_method_account_number:
      generalData &&
      generalData.payment_method &&
      generalData.payment_method.account_number
        ? generalData.payment_method.account_number
        : '',
    payment_method_bank: '',
    payment_method_bank_name:
      generalData &&
      generalData.payment_method &&
      generalData.payment_method.bank_name
        ? generalData.payment_method.bank_name
        : '',
    payment_method_bank_branch:
      generalData &&
      generalData.payment_method &&
      generalData.payment_method.bank_branch
        ? generalData.payment_method.bank_branch
        : '',
    auto_renewal: generalData.auto_renewal,
  });

  const [provinces, provincesHandler] = useState([]);
  const [cities, citiesHandler] = useState([]);
  const [barangays, barangaysHandler] = useState([]);
  const [cityLoading, cityLoadingHandler] = useState(false);
  const [barangayLoading, barangayLoadingHandler] = useState(false);
  const [riders, ridersHandler] = useState(null); //riders option
  // const [banks, banksHandler] = useState([]);
  const [banksOption, banksOptionHandler] = useState([]);
  const [selectedBank, selectedBankHandler] = useState({ key: '' });
  const [_bankLoading, _bankLoadingHandler] = useState(true);

  // const [_disableUpload, _disableUploadHandler] = useState(false);

  // const disabled_upload = async () => {
  //   const today = new Date (Date.now())
  //   const {
  //     data: { trails },
  //   } = await doFetchTimelineTicket(ticketNo);
  //   if (generalData.status_raw === "LR"){
  //     const lr_date = trails.find(el => el.activity_raw === "LR").inserted_at
  //     _disableUploadHandler((new Date(lr_date), today) >= 1)
  //   }
  //   _disableUploadHandler(false)
  // }

  const validateForm = () => {
    const newErrors = {
      address: formData.address ? '' : 'Street Address is required',
      province: formData.province ? '' : 'Province is required',
      city: formData.city ? '' : 'City is required',
      barangay: formData.barangay ? '' : 'Barangay is required'
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const getProvincesOptions = () => {
    doFetchProvinces({ page_size: -1 }).then((rdata) => {
      const {
        data: { provinces },
      } = rdata;

      provincesHandler(provinces);
    });
  };

  const getBanks = () => {
    _bankLoadingHandler(true);
    banksOptionHandler([]);
    doFetchCoinsBank().then((response) => {
      const { data, success } = response;

      if (success) {
        // const _data = [
        //   ...data,
        //   { id: "gcashx", name: "GCash" },
        //   { id: "paymayax", name: "Paymaya" },
        // ];
        const _data = [...data];
        const regx = /(\(.*\))/g;
        const keys = [
          ...new Set([
            ..._data.map((datum) => {
              return datum.name.replace(regx, '').trim();
            }),
          ]),
        ];

        const _options = keys.map((key, index) => {
          const instaPay = _data.find((datum) => {
            return datum.name === `${key} (Instapay)`;
          });

          const pesoNet = _data.find((datum) => {
            return datum.name === `${key} (PESONet)`;
          });

          const bankOption = _data.find((datum) => {
            return datum.name === key;
          });
          let labelName = key.split('~')[1];
          const r_data = {
            key: index,
            bankName: key,
            labelName,
            instaPay,
            pesoNet,
            bankOption,
          };

          return r_data;
        });

        banksOptionHandler(_options);
        _bankLoadingHandler(false);
        if (
          formData.payment_method_type === 'BD' ||
          formData.payment_method_type === 'CD'
        ) {
          //bank deposit
          // const _selectedBank = _options.find((e) =>
          //   [e.bankOption, e.instaPay, e.pesoNet]
          //     .filter((e) => Boolean(e))
          //     .map((e) => e.bankName)
          //     .includes(formData.payment_method_bank_name)
          // );
          const _selectedBank = formData.payment_method_bank_name
            ? formData.payment_method_bank_name
            : '';
          selectedBankHandler({ bankName: _selectedBank });
        }
      } else {
        banksOptionHandler([]);
        _bankLoadingHandler(false);
      }
    }).catch((err) => {
      console.log(err)
      doUpdateNotification({
        toggle: true,
        message: 'Something went wrong to get the entire list of banks.',
        color: 'warning',
      });
      banksOptionHandler([]);
      _bankLoadingHandler(false);
    });;
  };

  useEffect(() => {
    let cancel = false;

    if (!cancel) {
      getBanks();
      getProvincesOptions();
      const { province, city } = locationData;
      if (province && province.id) {
        getCitiesOption(province.id);
      }

      if (city && city.id) {
        getBarangayOption(city.id);
      }
      getRiders();
      // disabled_upload()
    }

    return () => {
      cancel = true;
    };

    // eslint-disable-next-line
  }, [location]);

  const walkinAddress =
    '7th Floor, Avecshares Center, 1132 University Parkway North, Bonifacio Global City, Taguig City 1634';

  const getCitiesOption = (province) => {
    if (province) {
      cityLoadingHandler(true);
      doFetchCities(province).then(({ data: { cities } }) => {
        citiesHandler(cities || []);
        cityLoadingHandler(false);
      });
    }
  };

  const getBarangayOption = (city) => {
    if (city) {
      barangayLoadingHandler(true);
      doFetchBarangays(city).then(({ data: { barangays } }) => {
        barangaysHandler(barangays || []);
        barangayLoadingHandler(false);
      });
    }
  };

  const getRiders = async () => {
    const { data, success } = await doFetchRiders();

    if (success) {
      ridersHandler(data);
    }
  };

  const cashoutMethodOptions = [
    {
      label: 'Cash',
      value: 'CH',
    },
    {
      label: 'Bank Deposit',
      value: 'BD',
    },
    {
      label: 'Coins',
      value: 'EW',
    },
    // {
    //   label: 'Cash card',
    //   value: 'CC',
    // },
    // {
    //   label: 'Bank Deposit (coins)',
    //   value: 'CD', //coins deposit
    // },
    // {
    //   label: 'IBFT',
    //   value: 'IB',
    // },
  ];

  const fireUpdate = () => {
    const {
      address,
      province,
      city,
      barangay,
      type,
      payment_method_type,
      payment_method_bank_name,
      payment_method_account_number,
      payment_method_bank_branch,
      preferred_date,
      auto_renewal,
    } = formData;
  
    const formattedDate = moment(preferred_date).format('YYYY-MM-DD 00:00:00');
  
    const location = type === 'walk-in'
      ? { type, preferred_date: formattedDate }
      : { address, province, city, barangay, type, preferred_date: formattedDate };
  
    const payment_method = {
      payment_method: payment_method_type,
      account_number: payment_method_account_number,
      bank_name: payment_method_bank_name,
      bank_branch: payment_method_bank_branch,
    };
  
    const _payload = {
      location,
      payment_method: Object.fromEntries(
        Object.entries(payment_method).filter(
          ([_, v]) => v != null && !isEmpty(v)
        )
      ),
      ticket_no: ticketNo,
      auto_renewal,
    };
  
    if (type !== 'walk-in' && !validateForm()) {
      doUpdateNotification({
        toggle: true,
        message:
          'Some fields are required. Please fill out all required fields.',
        color: 'danger',
      });
      return;
    }
  
    doMakeUpdateTicketInfo(_payload)
      .then((response) => {
        const { success } = response;
        doUpdateNotification({
          toggle: true,
          message: success ? 'Successfully updated ticket info' : 
            'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: success ? 'primary' : 'danger',
        });
      })
      .catch((err) => {
        doUpdateNotification({
          toggle: true,
          message: 'Something went wrong. Please refresh the page, or contact PawnHero IT support.',
          color: 'danger',
        });
      });
  };

  return (
    <>
      <div className='column is-6'>
        <TicketDataStyle className='columns' style={{ overflow: 'visible' }}>
          <div className='column is-6' style={{ borderRadius: '5px 0 0 5px' }}>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='sub-title'>Ticket Number</p>
                <p className='value'>{generalData.ticket_no}</p>
              </div>
              <div className='content-row'>
                <p className='sub-title'>Pawn Request</p>
                <p className='value'>
                  {moment(generalData.created_at).format(
                    'ddd, DD MMM YYYY HH:mm:ss'
                  )}
                </p>
              </div>
              {!isEmpty(locationData) && (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Pick-up Date</p>
                    {generalData.status_raw === 'KP' ||
                    generalData.status_raw === 'KW' ? (
                      <SimpleDatePicker
                        className='input'
                        value={formData.preferred_date}
                        onChange={(date) => {
                          formDataHandler({
                            ...formData,
                            preferred_date: date,
                          });
                        }}
                      />
                    ) : (
                      <p className='value'>
                        {moment(locationData.preferred_date).format(
                          'ddd, DD MMM YYYY'
                        )}
                      </p>
                    )}
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Receiving Type</p>
                    <div className='select'>
                      <select
                        value={formData.type.toLowerCase()}
                        onChange={(e) =>
                          formDataHandler({
                             ...formData,
                             type: e.target.value ,
                             address: '',
                             province: '',
                             city: '',
                             barangay: ''
                            })
                        }>
                        <option value=''>Select Type</option>
                        <option value='walk-in'>Walk-in</option>
                        <option value='pick-up'>Pick-up</option>
                      </select>
                    </div>
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Pickup Address</p>
                    {formData.type === 'walk-in' ? (
                      <>
                        <p className='value'>{walkinAddress}</p>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className='sub-title'>Street Address</p>
                          <input
                            type='text'
                            className='input'
                            value={formData.address}
                            style={errors.address ? { border: '1px solid red' } : {}}
                            onChange={(e) =>
                              formDataHandler({
                                ...formData,
                                address: e.target.value,
                              })
                            }
                          />
                          {errors.address && <p className='error'>{errors.address}</p>}
                        </div>

                        <div>
                          <p className='sub-title'>Province</p>
                          <div className='select'>
                            <select
                              value={formData.province}
                              style={errors.province ? { border: '1px solid red' } : {}}
                              onChange={(e) => {
                                formDataHandler({
                                  ...formData,
                                  province: e.target.value,
                                  city: '',
                                  barangay: '',
                                });
                                getCitiesOption(e.target.value);
                              }}
                            >
                              <option value=''>Select Province</option>
                              {provinces
                                .filter((e) => e.is_active)
                                .map((province, index) => (
                                  <Fragment key={index}>
                                    <option value={province.id}>{province.name}</option>
                                  </Fragment>
                                ))}
                            </select>
                          </div>
                          {errors.province && <p className='error'>{errors.province}</p>}
                        </div>

                        <div>
                          <p className='sub-title'>City</p>
                          <div className='select'>
                            {cityLoading ? (
                              <p>Loading...</p>
                            ) : (
                              <select
                                value={formData.city}
                                disabled={!formData.province}
                                style={errors.city ? { border: '1px solid red' } : {}}
                                onChange={(e) => {
                                  formDataHandler({
                                    ...formData,
                                    city: e.target.value,
                                    barangay: '',
                                  });
                                  getBarangayOption(e.target.value);
                                }}
                              >
                                <option value=''>Select City</option>
                                {cities.map((city, index) => (
                                  <Fragment key={index}>
                                    <option value={city.id}>{city.name}</option>
                                  </Fragment>
                                ))}
                              </select>
                            )}
                          </div>
                          {errors.city && <p className='error'>{errors.city}</p>}
                        </div>

                        <div>
                          <p className='sub-title'>Barangay</p>
                          <div className='select'>
                            {barangayLoading ? (
                              <p>Loading...</p>
                            ) : (
                              <select
                                value={formData.barangay}
                                disabled={!formData.city}
                                style={errors.barangay ? { border: '1px solid red' } : {}}
                                onChange={(e) =>
                                  formDataHandler({
                                    ...formData,
                                    barangay: e.target.value,
                                  })
                                }
                              >
                                <option value=''>Select Barangay</option>
                                {barangays.map((barangay, index) => (
                                  <Fragment key={index}>
                                    <option value={barangay.id}>{barangay.name}</option>
                                  </Fragment>
                                ))}
                              </select>
                            )}
                          </div>
                          {errors.barangay && <p className='error'>{errors.barangay}</p>}
                        </div>
                      </>
                    )}
                  </div>
                  {formData.type === 'Pick-up' && (
                    <div className='content-row'>
                      <p className='sub-title'>Courier</p>
                      <div className='select'>
                        <select
                          value={formData.rider}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              rider: e.target.value,
                            })
                          }>
                          <option value=''>Select Courier</option>
                          {riders &&
                            riders.map((rider, index) => {
                              return (
                                <Fragment key={index}>
                                  <option value={rider.rider_id}>
                                    {rider.name}
                                  </option>
                                </Fragment>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className='content-row'>
                <p className='sub-title'>Loan Duration</p>
                <p className='value'>{generalData.loan_duration} months</p>
              </div>
              {generalData.loan && (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Loan Maturity Date</p>
                    <p className='value'>
                      {generalData.loan.maturity_date || ''}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: moment().isAfter(
                            generalData.loan.maturity_date
                          )
                            ? '#e2574c'
                            : '#327fbe',
                        }}>{`(${generalData.loan.maturity_duration || ''})`}</span>
                    </p>
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>Loan Default Date</p>
                    <p className='value'>
                      {generalData.loan.default_date || ''}{' '}
                      <span
                        style={{
                          fontSize: '12px',
                          color: moment().isAfter(generalData.loan.default_date)
                            ? '#e2574c' //red
                            : '#327fbe',
                        }}>{`(${generalData.loan.default_duration || ''})`}</span>
                    </p>
                  </div>

                  {generalData.status_raw === 'LX' && (
                    <>
                      <div className='content-row'>
                        <p className='sub-title'>First Auction Date</p>
                        <p className='value'>
                          {generalData.loan.first_auction || ''}
                        </p>
                      </div>
                      <div className='content-row'>
                        <p className='sub-title'>Second Auction Date</p>
                        <p className='value'>
                          {generalData.loan.second_auction || ''}
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
              {generalData.storage_locations ? (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Item Storage Location</p>
                    <p className='value'>
                      {generalData.storage_locations.location || ''}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {generalData.wd_dtl && (
                    <>
                      <div className='content-row'>
                        <p className='sub-title'>Last Withdrawal number</p>
                        <p className='value'>
                          {generalData.wd_dtl.wd_no || ''}
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
              {generalData.payment_method && (
                <>
                  <div className='content-row'>
                    <p className='sub-title'>Cashout Method</p>
                    <div className='select'>
                      <select
                        value={formData.payment_method_type}
                        onChange={(e) => {
                          formDataHandler({
                            ...formData,
                            payment_method_type: e.target.value,
                          });
                        }}>
                        <option value=''>Select Option</option>
                        {cashoutMethodOptions &&
                          cashoutMethodOptions.map((option, index) => {
                            return (
                              <Fragment key={index}>
                                <option value={option.value}>
                                  {option.label}
                                </option>
                              </Fragment>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className='content-row'>
                    {(formData.payment_method_type === 'BD' ||
                      formData.payment_method_type === 'CD') && (
                      <>
                        <p className='sub-title'>Bank Name</p>

                        {_bankLoading ? (
                          <p>loading banks...</p>
                        ) : (
                          <>
                            <Select
                              classNamePrefix='ctm-select'
                              value={[
                                { value: '', label: 'Select Bank' },
                                ...banksOption.map((e) => ({
                                  value: e.bankName,
                                  label: e.labelName,
                                })),
                              ].find((e) => e.value === selectedBank.bankName)}
                              options={[
                                ...banksOption.map((e) => ({
                                  value: e.bankName,
                                  label: `${e.labelName}`,
                                })),
                              ]}
                              onChange={({ value: val }) => {
                                const _selectedBank = banksOption.find(
                                  (e) => e.bankName === val
                                );
                                selectedBankHandler(_selectedBank);
                                formDataHandler({
                                  ...formData,
                                  payment_method_bank_name: val,
                                });
                              }}
                            />

                            {/* {!isNil(selectedBank) && (
                              <>
                                <p className='sub-title'>Bank Deposit Method</p>
                                <div className='select'>
                                  <select
                                    value={formData.payment_method_bank_name}
                                    onChange={(e) =>
                                      formDataHandler({
                                        ...formData,
                                        payment_method_bank_name:
                                          e.target.value,
                                      })
                                    }>
                                    <option value=''>
                                      Select Bank Deposit Method{' '}
                                    </option>
                                    {selectedBank.bankOption && (
                                      <option
                                        value={selectedBank.bankOption.id}>
                                        {selectedBank.bankName}
                                      </option>
                                    )}
                                    {selectedBank.instaPay && (
                                      <option value={selectedBank.instaPay.id}>
                                        Instapay
                                      </option>
                                    )}
                                    {selectedBank.pesoNet && (
                                      <option value={selectedBank.pesoNet.id}>
                                        pesoNet
                                      </option>
                                    )}
                                  </select>
                                </div>
                              </>
                            )} */}
                          </>
                        )}
                        {/* <p className='sub-title'>Bank Branch</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.payment_method_bank_branch}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              payment_method_bank_branch: e.target.value,
                            })
                          }
                        /> */}

                        <p className='sub-title'>Account Number</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.payment_method_account_number}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              payment_method_account_number: e.target.value,
                            })
                          }
                        />
                      </>
                    )}

                    {formData.payment_method_type === 'EW' && (
                      <>
                        <p className='sub-title'>Account Number</p>
                        <input
                          type='text'
                          className='input'
                          value={formData.payment_method_account_number}
                          onChange={(e) =>
                            formDataHandler({
                              ...formData,
                              payment_method_account_number: e.target.value,
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className='content-row'>
                    <p className='sub-title'>
                      Auto Renewal{' '}
                      <span className='has-text-weight-semibold'>
                        (Ticket Level)
                      </span>
                    </p>
                    <div className='select'>
                      <select
                        value={formData.auto_renewal}
                        onChange={(e) => {
                          formDataHandler({
                            ...formData,
                            auto_renewal: e.target.value,
                          });
                        }}>
                        <option value=''>Select Option</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                  <div className='content-row'>
                    <button
                      className='button is-primary'
                      disabled={
                        isEmpty(formData.payment_method_type) ||
                        (formData.payment_method_type === 'BD'
                          ? isEmpty(formData.payment_method_bank_name)
                          : false)
                      }
                      onClick={() => {
                        fireUpdate();
                      }}>
                      Update
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='column is-6' style={{ borderRadius: ' 0 5px 5px 0' }}>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='value'>Signed Pawn Ticket</p>
                <TicketPhoto signedTicketCallback={signedTicketCallback}/>
              </div>
            </div>
            <div className='column-content-wrapper'>
              <div className='content-row'>
                <p className='value'>Pawn Ticket</p>
                <Link
                  to={`/tickets/pdf/${ticketNo}`}
                  target='_blank'
                  style={{
                    marginTop: '20px',
                    display: 'block',
                    width: '100%',
                  }}>
                  <button className='button is-success full-width'>
                    View PDF
                  </button>
                </Link>
              </div>
            </div>
            {generalData.loan && (
              <div className='column-content-wrapper'>
                <div className='content-row'>
                  <p className='value'>Bar Code</p>
                  <Barcode
                    value={ticketNo}
                    maturityDate={generalData.loan.maturity_date || ''}
                    barcodeText={barcodeText}
                  />
                </div>
              </div>
            )}
          </div>
        </TicketDataStyle>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  doFetchProvinces: (payload) => dispatch(fetchProvinces(payload)),
  doFetchCities: (provinceID) => dispatch(fetchCities(provinceID)),
  doFetchBarangays: (cityID) => dispatch(fetchBarangays(cityID)),
  doFetchRiders: () => dispatch(fetchRiders()),
  doMakeUpdateTicketInfo: (payload) => dispatch(makeUpdateTicketInfo(payload)),
  doUpdateNotification: (payload) => dispatch(updateNotification(payload)),
  doFetchCoinsBank: () => dispatch(fetchCoinsBank()),
  doFetchTimelineTicket: (ticketNo) => dispatch(fetchTimelineTicket(ticketNo)),
});

export default connect(null, mapDispatchToProps)(SupportTicketData);
